import { Icon } from '@iconify/react';
import pieChart2Fill from '@iconify/icons-eva/pie-chart-2-fill';
import peopleFill from '@iconify/icons-eva/people-fill';
import radarIcon from '@iconify/icons-bx/radar';
import versionIcon from '@iconify/icons-carbon/version';
import plusFill from '@iconify/icons-eva/plus-fill';
import fileTextFill from '@iconify/icons-eva/file-text-fill';
import personAddFill from '@iconify/icons-eva/person-add-fill';
import logoutIcon from '@iconify/icons-carbon/logout';
import settingsHorizontal from '@iconify/icons-akar-icons/settings-horizontal';
import bookOpenOutline from '@iconify/icons-eva/book-open-outline';
import cloudUploadFill from '@iconify/icons-eva/cloud-upload-fill';
import infoIcon from '@iconify/icons-eva/info-outline';

// ----------------------------------------------------------------------

const getIcon = (name) => <Icon icon={name} width={22} height={22} />;

const sidebarConfig = [
  {
    title: 'home',
    path: '/dashboard/app',
    icon: getIcon(pieChart2Fill),
    roles: ['admin', 'S&S', 'R&D'],
  },
  {
    title: 'radars',
    path: '/dashboard/radars',
    icon: getIcon(radarIcon),
    roles: ['admin', 'S&S'],
    children: [
      {
        title: 'list',
        path: '/dashboard/radars/list',
        icon: getIcon(radarIcon),
        roles: ['admin', 'S&S'],
      },
      {
        title: 'Create',
        path: '/dashboard/radars/create',
        icon: getIcon(plusFill),
        roles: ['admin', 'S&S'],
      },
      {
        title: 'Deleted',
        path: '/dashboard/radars/deleted',
        icon: getIcon(plusFill),
        roles: ['admin', 'S&S'],
      },
    ],
  },
  {
    title: 'softwares',
    path: '/dashboard/softwares',
    icon: getIcon(versionIcon),
    roles: ['admin', 'R&D'],
    children: [
      {
        title: 'Released',
        path: '/dashboard/softwares/installed',
        icon: getIcon(versionIcon),
        roles: ['admin', 'R&D'],
      },
      {
        title: 'Uploaded (create release)',
        path: '/dashboard/softwares/uploaded',
        icon: getIcon(fileTextFill),
        roles: ['admin', 'R&D'],
      },
      {
        title: 'Deleted',
        path: '/dashboard/softwares/backups',
        icon: getIcon(cloudUploadFill),
        roles: ['admin', 'R&D'],
      },
    ],
  },
  {
    title: 'users',
    path: '/dashboard/user',
    icon: getIcon(peopleFill),
    roles: ['admin', 'S&S'],
    children: [
      {
        title: 'list',
        path: '/dashboard/user/list',
        icon: getIcon(settingsHorizontal),
        roles: ['admin', 'S&S'],
      },
      {
        title: 'create',
        path: '/dashboard/user/new',
        icon: getIcon(personAddFill),
        roles: ['admin', 'S&S'],
      },
      {
        title: 'Deleted',
        path: '/dashboard/user/deleted',
        icon: getIcon(peopleFill),
        roles: ['admin', 'S&S'],
      },
    ],
  },
  {
    title: 'account',
    path: '/dashboard/account',
    icon: getIcon(settingsHorizontal),
    roles: ['admin', 'S&S', 'R&D'],
  },
  {
    title: 'Logs',
    path: '/dashboard/logs',
    icon: getIcon(bookOpenOutline),
    roles: ['admin'],
  },
  {
    title: 'Info',
    path: '/dashboard/info',
    icon: getIcon(infoIcon),
    roles: ['admin', 'S&S', 'R&D'],
  },
  {
    title: 'logout',
    path: '/logout',
    icon: getIcon(logoutIcon),
    roles: ['admin', 'S&S', 'R&D'],
  },
];

export default sidebarConfig;
