import { differenceInHours, differenceInDays, addDays } from 'date-fns';

export function getTimeTillRemoval(createdAt) {
  // Check if createdAt is a valid date
  if (!createdAt || isNaN(new Date(createdAt))) {
    return { days: null, hours: null, error: 'Invalid date' };
  }

  const removalDate = addDays(new Date(createdAt), 30); // set the removal date to createdAt + 30 days
  const now = new Date();

  if (removalDate <= now) {
    return { days: 0, hours: 0 };
  }

  const timeRemainingInDays = differenceInDays(removalDate, now);
  const timeRemainingInHours = differenceInHours(removalDate, now) % 24;

  return {
    days: timeRemainingInDays,
    hours: timeRemainingInHours,
  };
}
