import React from 'react';

// material
import {
  Typography,
  Card,
  Stack,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Autocomplete,
  TextField,
  Button,
  Grid,
} from '@mui/material';

// components
import DownloadProgress from 'src/components/common/DownloadProgress';
import Iconify from '../../../components/Iconify';
import outlineLinkOff from '@iconify/icons-ic/outline-link-off';
import { format } from 'date-fns';

const RadarSoftwareCard = ({
  radar,
  downloadStatus,
  downloadProgress,
  handleStopDownload,
  handleDownloadSoftware,
  setOpenUnassignSoftwareDialog,
  stype,
  changeStype,
  sortedNotRecalledSoftwares,
  resetversionfield,
  software,
  setSoftware,
  handleAssignSoftware,
}) => {
  return (
    <Grid item style={{ marginBottom: '1rem' }}>
      <Typography variant='h4' gutterBottom>
        Software of radar
      </Typography>
      <Card
        sx={{
          p: 2,
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          minWidth: '60vw',
          maxWidth: '32.5rem',
        }}
      >
        {radar?.software ? (
          <>
            <Stack
              direction='column'
              alignItems='flex-start'
              justifyContent='space-around'
            >
              <Typography variant='body1' gutterBottom>
                <b>ID of software: </b> {radar?.software?._id}
              </Typography>
              <Typography variant='body1' gutterBottom>
                <b>Version: </b> {radar?.software?.version}
              </Typography>
              <Typography variant='body1' gutterBottom>
                <b>Software type: </b> {radar?.software?.softwareType}
              </Typography>
              <Typography variant='body1' gutterBottom>
                <b>Radar type(s): </b>
                {radar &&
                radar.software &&
                Array.isArray(radar.software.radarType) &&
                radar.software.radarType.length
                  ? radar.software.radarType.join(', ')
                  : 'Not available'}
              </Typography>
              <Typography variant='body1' gutterBottom>
                <b>Ubuntu version(s): </b>
                {radar?.software?.ubuntuVersion?.length
                  ? radar?.software?.ubuntuVersion.join(', ')
                  : ' - '}
              </Typography>
              <Typography variant='body1' gutterBottom>
                <b>Software release path: </b> {radar?.software?.softwarePath}
              </Typography>
              <Typography variant='body1' gutterBottom>
                <b>Recalled: </b>
                {radar?.software?.recalled == false ? ' no.' : ' yes.'}
              </Typography>
            </Stack>
            <Stack
              direction='row'
              alignItems='flex-start'
              justifyContent='space-between'
              mt={2}
              mb={2}
              gap='2rem'
            >
              <Button
                sx={{ textTransform: 'none' }}
                variant='contained'
                onClick={
                  downloadStatus === 'downloading'
                    ? handleStopDownload
                    : handleDownloadSoftware
                }
                startIcon={
                  <Iconify
                    icon={
                      downloadStatus === 'downloading'
                        ? 'ic:baseline-stop'
                        : 'ic:baseline-download'
                    }
                  />
                }
              >
                {downloadStatus === 'downloading'
                  ? 'Stop download'
                  : 'Download software'}
              </Button>

              <Button
                variant='contained'
                color='error'
                onClick={() => setOpenUnassignSoftwareDialog(true)}
                startIcon={<Iconify icon={outlineLinkOff} />}
                sx={{ textTransform: 'none' }}
              >
                Unassign software
              </Button>
            </Stack>
            <DownloadProgress
              downloadStatus={downloadStatus}
              downloadProgress={downloadProgress}
            />
          </>
        ) : (
          <>
            <Typography variant='body1' gutterBottom>
              No software assigned to this radar yet.
            </Typography>
            <Stack
              direction='column'
              alignItems='flex-start'
              justifyContent='flex-start'
              gap='1rem'
            >
              <FormControl required error={stype === ''}>
                <InputLabel id='softwareTypeid'>Software type</InputLabel>
                <Select
                  sx={{ minWidth: 500, maxWidth: 650 }}
                  labelId='selectsoftwareType-label'
                  id='selectsoftwareType'
                  value={stype}
                  label='Software Type'
                  onChange={changeStype}
                >
                  <MenuItem value=''>
                    <em>None</em>
                  </MenuItem>
                  {Array.from(
                    new Set(
                      sortedNotRecalledSoftwares.map(
                        (software) => software.softwareType
                      )
                    )
                  ).map((type) => (
                    <MenuItem key={type} value={type}>
                      {type}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl>
                <Autocomplete
                  sx={{ minWidth: 500, maxWidth: 650 }}
                  key={resetversionfield}
                  disabled={!stype}
                  id='selectversion'
                  options={sortedNotRecalledSoftwares.filter(
                    (sw) => sw.softwareType === stype
                  )}
                  getOptionLabel={(option) =>
                    option.version +
                    ' - ' +
                    format(new Date(option.createdAt), 'yyyy-MM-dd, HH:mm:ss')
                  }
                  value={software}
                  onChange={(event, newValue) => {
                    setSoftware(newValue);
                  }}
                  renderOption={(props, option) => {
                    return (
                      <li {...props} key={option._id}>
                        <>
                          <b>{option.version}</b>
                          {' - ' +
                            format(
                              new Date(option.createdAt),
                              'yyyy-MM-dd, HH:mm:ss'
                            )}
                        </>
                      </li>
                    );
                  }}
                  renderInput={(params) => (
                    <TextField {...params} label='Software version' />
                  )}
                />
              </FormControl>
            </Stack>
            <Stack
              direction='row'
              alignItems='flex-start'
              justifyContent='flex-start'
              mt={2}
              gap='2rem'
            >
              <Button
                variant='contained'
                onClick={handleAssignSoftware}
                disabled={!software}
                startIcon={<Iconify icon='eva:plus-fill' />}
                sx={{ textTransform: 'none' }}
              >
                Add software
              </Button>
            </Stack>
          </>
        )}
      </Card>
    </Grid>
  );
};

export default RadarSoftwareCard;
