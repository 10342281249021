import { useArray } from 'src/hooks';
import React, { useState, useEffect, useReducer, useContext } from 'react';
import { toast } from 'react-toastify';
import { makeReq, handleCatch } from 'src/utils/makeReq';
import { AuthContext } from './AuthContext';
import { RadarsContext } from './RadarsContext';
import { useNavigate } from 'react-router-dom';

export const SoftwaresContext = React.createContext();

export const SoftwaresProvider = ({ children }) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const { user } = useContext(AuthContext);
  const { fetchRadars } = useContext(RadarsContext);

  // This custom hook is used to manage RELEASED softwares.
  const [
    softwares,
    setSoftwares,
    pushSoftware,
    filterSoftware,
    updateSoftware,
    removeSoftware,
    clearSoftware,
  ] = useArray([], '_id');

  const [sizeMetrics, setSizeMetrics] = useState([]);

  const fetchSoftwares = async () => {
    try {
      const resData = await makeReq(`/softwares`);
      setSoftwares(resData.softwares);
    } catch (err) {
      handleCatch(err);
    } finally {
      setLoading(false);
    }
  };

  const getSoftwareById = (id) => {
    return softwares.find((el) => el._id === id);
  };

  const fetchSoftwaresPage = async () => {
    try {
      const resData = await makeReq(`/softwares`);
      setSoftwares(resData.softwares);
    } catch (err) {
      handleCatch(err);
    } finally {
      setLoading(false);
    }
  };

  // Fetch it once user logged in
  useEffect(() => {
    if (!user) return;
    fetchSoftwares();
    fetchSizeMetrics();
  }, [user]);

  // * CRUD Operations
  const createSoftware = async (state, callback) => {
    try {
      const resData = await makeReq(`/softwares`, { body: state }, 'POST');
      toast.success('Software has been created successfully!');
      pushSoftware(resData.software);
      callback?.();
    } catch (err) {
      handleCatch(err);
    }
  };

  const deleteSoftware = async (id) => {
    try {
      await makeReq(`/softwares/${id}`, {}, 'DELETE');
      fetchRadars();
      navigate('/dashboard/softwares/installed');
      removeSoftware(id);
      setTimeout(() => {
        toast.success('Software has been deleted successfully!');
      }, 100);
    } catch (err) {
      handleCatch(err);
    }
  };

  const checkUsedSoftwares = async (id) => {
    try {
      const resData = await makeReq(`/softwares/checkused/${id}`, {}, 'GET');
      return resData;
    } catch (err) {
      handleCatch(err);
    }
  };

  // Download software FILE.
  const downloadSoftware = async (
    id,
    downloadFileName,
    onProgress,
    abortController
  ) => {
    try {
      const resData = await makeReq(
        `/softwares/download/${id}`,
        { fileName: downloadFileName, onProgress },
        'GET',
        true,
        abortController
      );
      toast.success('Software has been downloaded successfully!');
    } catch (err) {
      handleCatch(err);
    }
  };

  const fetchSizeMetrics = async () => {
    try {
      const resData = await makeReq(`/softwares/sizemetrics`, {}, 'GET');
      setSizeMetrics(resData);
      return resData;
    } catch (err) {
      handleCatch(err);
    }
  };

  return (
    <SoftwaresContext.Provider
      displayName='Softwares Context'
      value={{
        loading,
        softwares,
        getSoftwareById,
        createSoftware,
        deleteSoftware,
        fetchSoftwaresPage,
        downloadSoftware,
        checkUsedSoftwares,
        sizeMetrics,
        fetchSoftwares,
      }}
    >
      {children}
    </SoftwaresContext.Provider>
  );
};
