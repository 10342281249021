import { Link as RouterLink } from 'react-router-dom';
import { useContext, useState } from 'react';
import NotFound from '../Page404';
import { Icon } from '@iconify/react';
import eyeFill from '@iconify/icons-eva/eye-fill';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';
import PropTypes from 'prop-types';

// material
import {
  Button,
  TextField,
  Stack,
  Tabs,
  Tab,
  Box,
  Container,
  Typography,
  Skeleton,
  Card,
  Breadcrumbs,
  Link,
} from '@mui/material';

// icons
import KeyIcon from '@mui/icons-material/Key';
import settings2Fill from '@iconify/icons-eva/settings-2-fill';

// other material
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';

// password validation
import * as Yup from 'yup';
import { useFormik, Form, FormikProvider } from 'formik';

// components
import Page from '../../components/Page';
import { toast } from 'react-toastify';

// contexts
import { AuthContext } from 'src/Contexts/AuthContext';

// format for date
import { format } from 'date-fns';

//

// functions to tab functionality
function TabPanel(props) {
  const { children, value, index, classes, ...other } = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Container>
          <Box>{children}</Box>
        </Container>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

//function to get tab props
function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function AccountDetails() {
  //
  const { loading, user } = useContext(AuthContext);
  const { changeMyPassword } = useContext(AuthContext);

  //
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  // validation schema for password update (Yup)
  const FormSchema = Yup.object().shape({
    password: Yup.string()
      .min(8, 'Password must be 8 characters long')
      .matches(/[0-9]/, 'Password requires a number')
      .matches(/[a-z]/, 'Password requires a lowercase letter')
      .matches(/[A-Z]/, 'Password requires an uppercase letter')
      .matches(/[^\w]/, 'Password requires a symbol'),
    confirm: Yup.string()
      .required('Confirm password is required') // add this line
      .oneOf([Yup.ref('password'), null], 'Must match "password" field value'),
  });

  const formik = useFormik({
    initialValues: {
      password: '',
      confirm: '',
    },
    validationSchema: FormSchema,
    onSubmit: async () => {
      try {
        await changeMyPassword(values.password);
        // clear form
        formik.resetForm();
      } catch (err) {
        // console.log(err);
        toast.error(err.message);
      }
    },
  });

  const { errors, touched, values, handleSubmit, getFieldProps } = formik;

  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };

  const handleShowConfirmPassword = () => {
    setShowConfirmPassword((show) => !show);
  };

  // for tabs functionality
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Page title={'Account | Robin Radar Systems Admin'}>
      {loading ? (
        <Skeleton variant='rectangular' height='600px' />
      ) : user ? (
        <>
          <Breadcrumbs aria-label='breadcrumb'>
            <Link
              component={RouterLink}
              to='/'
              underline='hover'
              color='inherit'
            >
              Home
            </Link>
            <Typography color='text.primary'>Account</Typography>
          </Breadcrumbs>

          <Container>
            <Typography variant='h4' gutterBottom>
              Account
            </Typography>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label='basic tabs example'
              >
                <Tab
                  icon={<Icon icon={settings2Fill} width={20} height={20} />}
                  iconPosition='start'
                  label='General'
                  {...a11yProps(0)}
                />
                <Tab
                  icon={<KeyIcon />}
                  iconPosition='start'
                  label='Change Password'
                  {...a11yProps(1)}
                />
              </Tabs>
            </Box>

            <Card>
              <Container>
                <TabPanel value={value} index={0}>
                  <Stack
                    direction='column'
                    alignItems='flex-start'
                    justifyContent='space-around'
                    mb={5}
                    mt={2}
                  >
                    <Typography variant='h5' gutterBottom>
                      {user.name}
                    </Typography>
                    <Typography variant='body1' gutterBottom>
                      <b>Email: </b>
                      {user.email}
                    </Typography>
                    <Typography variant='body1' gutterBottom>
                      <b>Phone: </b> {user.phone ? user.phone : 'N/A'}
                    </Typography>
                    <Typography variant='body1' gutterBottom>
                      <b>Account ID: </b> {user._id}
                    </Typography>
                    <Typography variant='body1' gutterBottom>
                      <b>Status:</b>
                      {user.activated.toString() == 'true'
                        ? ' Account is activated.'
                        : ' Account is not activated yet.'}
                    </Typography>
                    <Typography variant='body1' gutterBottom>
                      <b>Registration: </b>
                      {user?.createdAt
                        ? format(
                            new Date(user.createdAt),
                            'yyyy-MM-dd, HH:mm:ss'
                          )
                        : 'N/A'}
                    </Typography>
                  </Stack>
                </TabPanel>
                <TabPanel value={value} index={1}>
                  <FormikProvider value={formik}>
                    <Form autoComplete='off' noValidate onSubmit={handleSubmit}>
                      <Stack
                        direction='column'
                        alignItems='flex-start'
                        justifyContent='space-around'
                        spacing={2}
                        mb={5}
                        mt={2}
                      >
                        <Typography variant='h5' gutterBottom>
                          Change Password
                        </Typography>
                        <TextField
                          id='new-password-input'
                          label='New password'
                          type={showPassword ? 'text' : 'password'}
                          {...getFieldProps('password')}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position='end'>
                                <IconButton
                                  onClick={handleShowPassword}
                                  edge='end'
                                >
                                  <Icon
                                    icon={showPassword ? eyeFill : eyeOffFill}
                                  />
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                          error={Boolean(touched.password && errors.password)}
                          helperText={touched.password && errors.password}
                        />

                        <TextField
                          id='confirm-password-input'
                          label='Confirm new password'
                          type={showConfirmPassword ? 'text' : 'password'}
                          {...getFieldProps('confirm')}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position='end'>
                                <IconButton
                                  onClick={handleShowConfirmPassword}
                                  edge='end'
                                >
                                  <Icon
                                    icon={
                                      showConfirmPassword ? eyeFill : eyeOffFill
                                    }
                                  />
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                          error={Boolean(touched.confirm && errors.confirm)}
                          helperText={touched.confirm && errors.confirm}
                        />

                        <Button
                          sx={{ textTransform: 'none' }}
                          color='primary'
                          variant='contained'
                          type='submit'
                          disabled={
                            !formik.isValid ||
                            !formik.dirty ||
                            formik.isSubmitting
                          }
                        >
                          Change password
                        </Button>
                      </Stack>
                    </Form>
                  </FormikProvider>
                </TabPanel>
              </Container>
            </Card>
          </Container>
        </>
      ) : (
        <NotFound />
      )}
    </Page>
  );
}
