import { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { filter } from 'lodash';

// material
import {
  Card,
  Table,
  Stack,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  Skeleton,
  Button,
  Tooltip,
} from '@mui/material';

// icons
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';

// components
import Page from '../../../components/Page';
import Scrollbar from '../../../components/Scrollbar';
import Alert from '@mui/material/Alert';
import SearchNotFound from '../../../components/SearchNotFound';
import {
  SoftwareListHead,
  SoftwareListToolbar,
} from '../../../components/_dashboard/software';
import AlertDialog from 'src/components/dialogs/AlertDialog';
import Label from '../../../components/Label.js';

//
import { BackupContext } from 'src/Contexts/BackupContext';
import { v4 } from 'uuid';

// breadcrumb
import { Link as RouterLink } from 'react-router-dom';
import Link from '@mui/material/Link';
import Breadcrumbs from '@mui/material/Breadcrumbs';

// import format for date
import format from 'date-fns/format';

// Utils
import { getTimeTillRemoval } from 'src/utils/getTimeTillRemoval';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  {
    _id: v4(),
    id: 'releasedVersionBackup',
    label: 'Type',
    alignRight: false,
  },
  {
    _id: v4(),
    id: 'version',
    label: 'Version',
    alignRight: false,
  },
  {
    _id: v4(),
    id: 'softwareType',
    label: 'Software type',
    alignRight: false,
  },
  {
    _id: v4(),
    id: 'ubuntuVersion',
    label: 'Ubuntu version',
    alignRight: false,
  },
  {
    _id: v4(),
    id: 'removedBy',
    label: 'Removed by',
    alignRight: false,
  },
  {
    _id: v4(),
    id: 'removeReason',
    label: 'Removal reason',
    alignRight: false,
  },
  {
    _id: v4(),
    id: 'createdAt',
    label: 'Removal date',
    alignRight: false,
  },
  {
    _id: v4(),
    id: 'createdAt',
    label: 'Removal countdown',
    alignRight: false,
  },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  const keys = orderBy.split('.');
  const aProperty = keys.length > 1 ? a?.[keys[0]]?.[keys[1]] : a?.[orderBy];
  const bProperty = keys.length > 1 ? b?.[keys[0]]?.[keys[1]] : b?.[orderBy];

  if (!aProperty && !bProperty) {
    return 0;
  }
  if (!aProperty) {
    return 1;
  }
  if (!bProperty) {
    return -1;
  }
  if (bProperty < aProperty) {
    return -1;
  }
  if (bProperty > aProperty) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array = [], comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(
      array,
      (_backups) =>
        _backups.version.toLowerCase().indexOf(query.toLowerCase()) !== -1
    );
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function DeletedSoftwares() {
  // Context
  const { backups, loading, deleteAllBackups } = useContext(BackupContext);

  //
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('createdAt');
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const navigate = useNavigate();

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  const emptyRows =
    page > 0
      ? Math.max(0, (1 + page) * rowsPerPage - (backups?.length || 0))
      : 0;

  const filteredSoftwares = applySortFilter(
    backups,
    getComparator(order, orderBy),
    filterName
  );

  const isSoftwareNotFound = filteredSoftwares.length === 0;

  // State for the dialog
  const [openRemoveAllBackupsDialog, setOpenRemoveAllBackupsDialog] =
    useState(false);

  const handleRemoveAllBackups = () => {
    deleteAllBackups();
    setOpenRemoveAllBackupsDialog(false);
  };

  return (
    <Page title='Deleted softwares | Robin Radar Systems Admin'>
      <Breadcrumbs aria-label='breadcrumb'>
        <Link component={RouterLink} to='/' underline='hover' color='inherit'>
          Home
        </Link>
        <Typography color='text.primary'>Deleted softwares</Typography>
      </Breadcrumbs>
      <Container>
        <Stack
          direction='row'
          alignItems='center'
          justifyContent='space-between'
          mb={5}
        >
          <Typography variant='h4' gutterBottom>
            Deleted softwares
          </Typography>
        </Stack>
        <Stack
          direction='row'
          alignItems='center'
          justifyContent='space-between'
          sx={{ mb: 2 }}
        >
          <Alert severity='warning'>
            Deleted softwares are permanently removed from the database after{' '}
            <b>30 days</b>.
          </Alert>
        </Stack>
        <Card>
          <Stack
            direction='row'
            alignItems='center'
            justifyContent='space-between'
            mr={3}
          >
            <SoftwareListToolbar
              numSelected={selected.length}
              filterName={filterName}
              onFilterName={handleFilterByName}
              slug='for version...'
            />
            <Button
              variant='contained'
              color='error'
              startIcon={<DeleteForeverIcon />}
              onClick={() => setOpenRemoveAllBackupsDialog(true)}
              sx={{ textTransform: 'none' }}
            >
              Permanent delete all
            </Button>
          </Stack>
          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <SoftwareListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={backups?.length || 0}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  noCheckBox
                />
                <TableBody>
                  {loading
                    ? Array(5)
                        .fill()
                        .map(() => {
                          return (
                            <TableRow
                              hover
                              key={v4()}
                              tabIndex={-1}
                              role='checkbox'
                              sx={{
                                cursor: 'pointer',
                                textDecoration: 'none',
                              }}
                            >
                              {Array(9)
                                .fill()
                                .map(() => (
                                  <TableCell key={v4()} align='right'>
                                    <Skeleton />
                                  </TableCell>
                                ))}
                            </TableRow>
                          );
                        })
                    : filteredSoftwares

                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map((row) => {
                          // ROW variables
                          const {
                            _id,
                            releasedVersionBackup,
                            version,
                            removedBy,
                            removeReason,
                            softwareType,
                            ubuntuVersion,
                            createdAt,
                          } = row;

                          const timeTillRemoval = getTimeTillRemoval(createdAt); // Pass createdAt to getTimeTillRemoval function

                          const isItemSelected =
                            selected.indexOf(version) !== -1;

                          return (
                            <TableRow
                              hover
                              key={_id}
                              tabIndex={-1}
                              role='checkbox'
                              selected={isItemSelected}
                              aria-checked={isItemSelected}
                              sx={{
                                cursor: 'pointer',
                                textDecoration: 'none',
                              }}
                              onClick={() =>
                                navigate(`/dashboard/backups/${row._id}`)
                              }
                            >
                              <TableCell padding='checkbox'></TableCell>
                              <TableCell align='left'>
                                <Label
                                  color={
                                    releasedVersionBackup.toString() === 'true'
                                      ? 'error'
                                      : 'warning'
                                  }
                                >
                                  {releasedVersionBackup.toString() === 'true'
                                    ? ' Released'
                                    : ' Uploaded'}
                                </Label>
                              </TableCell>
                              <TableCell align='left'>{version}</TableCell>
                              <TableCell align='left'>
                                {softwareType || ' - '}
                              </TableCell>
                              <TableCell align='left'>
                                {ubuntuVersion?.length
                                  ? ubuntuVersion.join(', ')
                                  : ' - '}
                              </TableCell>
                              <TableCell align='left'>
                                {removedBy || ' - '}
                              </TableCell>
                              <Tooltip
                                title={removeReason}
                                placement='top-start'
                                arrow
                              >
                                <TableCell align='left'>
                                  {removeReason.length > 45
                                    ? removeReason.substr(0, 45) + '...'
                                    : removeReason}
                                </TableCell>
                              </Tooltip>
                              <TableCell align='left'>
                                {createdAt && !isNaN(new Date(createdAt))
                                  ? format(
                                      new Date(createdAt),
                                      'yyyy-MM-dd, HH:mm:ss'
                                    )
                                  : 'N/A'}
                              </TableCell>

                              <TableCell align='left'>
                                {timeTillRemoval.error
                                  ? 'N/A'
                                  : `${timeTillRemoval.days} days ${timeTillRemoval.hours} hours`}
                              </TableCell>
                            </TableRow>
                          );
                        })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
                {isSoftwareNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell
                        align='center'
                        colSpan={TABLE_HEAD.length + 1}
                        sx={{ py: 3 }}
                      >
                        <SearchNotFound searchQuery={filterName} />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25, 50, 100]}
            component='div'
            count={backups?.length || 0}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>
      {openRemoveAllBackupsDialog && (
        <AlertDialog
          onConfirm={handleRemoveAllBackups}
          onCancel={() => setOpenRemoveAllBackupsDialog(false)}
          title={`Are you sure you want to permanently remove all files?`}
          description={
            'This action cannot be undone. All files will be removed permanently from the server and the database.'
          }
        />
      )}
    </Page>
  );
}
