import { useArray } from 'src/hooks';
import React, { useState, useEffect, useReducer, useContext } from 'react';
import { toast } from 'react-toastify';
import { makeReq, handleCatch } from 'src/utils/makeReq';
import { AuthContext } from './AuthContext';
import { SoftwaresContext } from './SoftwaresContext';
import { useNavigate } from 'react-router-dom';

export const UploadedVersionContext = React.createContext();

export const UploadedVersionProvider = ({ children }) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [loadingDe, setLoadingDe] = useState(true);
  const [countSoftwares, setCountSoftwares] = useState(0);
  const { user } = useContext(AuthContext);
  const { fetchSoftwaresPage } = useContext(SoftwaresContext);

  // Custom hooks to manage softwares.
  const [
    softwares,
    setSoftwares,
    pushSoftwares,
    filterSoftwares,
    updateSoftwares,
    removeSoftwares,
    clearSoftwares,
  ] = useArray([], '_id');

  const [
    softwaredetails,
    setExtractedVersion,
    pushExtractedVersion,
    filterExtractedVersion,
    updateExtractedVersion,
    removeExtractedVersion,
    clearExtractedVersion,
  ] = useArray([], '_id');

  const [
    declaredsoftware,
    pushDeclaredSoftware,
    filterDeclaredSoftware,
    updateDeclaredSoftware,
    removeDeclaredSoftware,
    clearDeclaredSoftware,
  ] = useArray([], '_id');

  //
  const fetchSoftwares = async () => {
    try {
      const resData = await makeReq(`/softwares/versions`);
      setSoftwares(resData.softwares); // release version table data
      setCountSoftwares(resData.softwares.length); // home page counter for release version
    } catch (err) {
      handleCatch(err);
    } finally {
      setLoading(false);
    }
  };

  const getSoftwareById = (id) => {
    return softwares.find((el) => el._id === id);
  };

  // extract version for upload software version details
  const fetchExtractedVersion = async (_id, rtype) => {
    setLoadingDe(true);
    try {
      if (_id != undefined) {
        const resData = await makeReq(
          `/softwares/exractversion`,
          { body: { version: _id, type: rtype } }, //type: 'birdradar' to find which type of radar to extract
          'POST'
        );
        setExtractedVersion(resData.softwaredetails);
      }
    } catch (err) {
      handleCatch(err);
      //navigate back to create new release page if can not extract data
      setTimeout(() => {
        navigate('/dashboard/softwares/uploaded');
      }, 10);
    } finally {
      setLoadingDe(false);
    }
  };

  // Fetch it once user logged in
  useEffect(() => {
    if (!user) return;
    fetchSoftwares();
  }, [user]);

  // * CRUD Operations
  const releaseVersion = async (
    _version,
    _versionplusextra,
    _radartype,
    _validTypes,
    _ubuntuVersion
  ) => {
    try {
      const resData = await makeReq(
        `/softwares/uploaded`,
        {
          body: {
            version: _version,
            versionplusextra: _versionplusextra,
            radartype: _radartype,
            validTypes: _validTypes,
            ubuntuVersion: _ubuntuVersion,
          },
        },
        'POST'
      );
      pushDeclaredSoftware(resData.software);

      fetchSoftwaresPage();

      if (resData.status === 'success') {
        navigate('/dashboard/softwares/installed');
        setTimeout(() => {
          toast.success('Software has been declared successfully!');
        }, 100);
      }
    } catch (err) {
      if (err.message.includes('Error creating zip')) {
        toast.error('Error creating zip');
      } else if (err.message.includes("Can't create software")) {
        toast.error("Can't create software, try again");
      } else {
        handleCatch(err);
      }
    }
  };

  // After downloading, replace user._id with the appropriate user ID in the sd-upload-config.yml file
  const downloadUploadConfigFile = async () => {
    try {
      const fileName = `sd-upload-config.yml`;
      const resData = await makeReq(
        `/softwares/uploadconfig`,
        { fileName },
        'GET',
        true
      );

      if (!resData || resData.error) {
        toast.error('Error downloading the config file');
        return;
      }

      toast.success('Config file has been downloaded successfully!');
    } catch (err) {
      handleCatch(err);
    }
  };

  const downloadNotReleasedSoftware = async (
    _versionPath,
    downloadFileName,
    onProgress
  ) => {
    try {
      const resData = await makeReq(
        `/softwares/downloadnr`,
        {
          body: {
            softwarePath: _versionPath,
          },
          fileName: downloadFileName,
          onProgress,
        },
        'POST',
        true
      );
      toast.success('Software has been downloaded successfully!');
    } catch (err) {
      handleCatch(err);
    }
  };

  return (
    <UploadedVersionContext.Provider
      displayName='Softwares Context'
      value={{
        loading,
        softwares,
        fetchSoftwares,
        getSoftwareById,
        loadingDe,
        softwaredetails,
        fetchExtractedVersion,
        releaseVersion,
        countSoftwares,
        downloadUploadConfigFile,
        setSoftwares,
        downloadNotReleasedSoftware,
      }}
    >
      {children}
    </UploadedVersionContext.Provider>
  );
};
