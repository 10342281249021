import React from 'react';
import { Autocomplete, Box, TextField } from '@mui/material';

const CountryAutocomplete = ({
  id,
  resetKey, // rename key to resetKey
  minWidth,
  maxWidth,
  countries,
  countryCode,
  setCountryCode,
}) => {
  return (
    <Autocomplete
      key={resetKey} // use resetKey here
      id={id}
      sx={{ minWidth: minWidth, maxWidth: maxWidth }}
      options={countries}
      autoHighlight
      getOptionLabel={(option) => (option ? option.label : '')}
      isOptionEqualToValue={(option, value) => option.name === value.name}
      value={countryCode}
      onChange={(event, newValue) => {
        setCountryCode(newValue);
        console.log(newValue);
      }}
      renderOption={(props, option) => (
        <Box
          component='li'
          sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
          {...props}
        >
          <img
            loading='lazy'
            width='20'
            src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
            srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
            alt=''
          />
          {option.label} ({option.code}) +{option.phone}
        </Box>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          label='Radar location (country)'
          inputProps={{
            ...params.inputProps,
            autoComplete: 'new-password', // disable autocomplete and autofill
          }}
        />
      )}
    />
  );
};

export default CountryAutocomplete;
