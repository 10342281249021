import {
  Stack,
  Container,
  Typography,
  Skeleton,
  Card,
  Grid,
  Button,
} from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router';
import NotFound from '../Page404';
import Page from '../../components/Page';
import { UsersContext } from 'src/Contexts/UsersContext';
import { toast } from 'react-toastify';
import Iconify from '../../components/Iconify';
import { format } from 'date-fns';

// components
import { useNavigate, Link as RouterLink } from 'react-router-dom';
import Link from '@mui/material/Link';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import ConfirmationDialog from 'src/components/dialogs/ConfirmationDialog';

export default function UserDetails() {
  // Context
  const { users, getUserById, deleteUser, loading } = useContext(UsersContext);

  const { id } = useParams();

  const [user, setUser] = useState();
  const [userNotFound, setUserNotFound] = useState(false);

  // State for dialogs
  const [openRemoveUserDialog, setOpenRemoveUserDialog] = useState(false);

  //
  const navigate = useNavigate();

  useEffect(() => {
    if (!id) return;
    if (loading) return;

    let el = getUserById(id);

    if (!el) {
      setUserNotFound(true);
      toast.error("The user with this id doesn't exist.");
    } else {
      setUser({ ...el });
    }
  }, [getUserById, users, loading, id]);

  const handleRemoveUser = (inputText) => {
    navigate('/dashboard/user/list');
    deleteUser(user?._id, inputText);
    setOpenRemoveUserDialog(false);
  };

  return (
    <>
      {userNotFound || loading ? (
        <Page title='404 | Not Found'>
          {loading ? (
            <Skeleton variant='rectangular' height='600px' />
          ) : (
            <NotFound />
          )}
        </Page>
      ) : (
        <Page title={user?.name + ' | Robin Radar Systems Admin'}>
          <Breadcrumbs aria-label='breadcrumb'>
            <Link
              component={RouterLink}
              to='/'
              underline='hover'
              color='inherit'
            >
              Home
            </Link>
            <Link
              component={RouterLink}
              to='/dashboard/user/list'
              underline='hover'
              color='inherit'
            >
              Users
            </Link>
            <Typography color='text.primary'>{user?.name}</Typography>
          </Breadcrumbs>
          <Container>
            <Grid
              container
              direction='column'
              justifyContent='flex-start'
              alignItems='flex-start'
              spacing={2}
              sx={{ mt: 1 }}
            >
              <Grid item>
                <Typography variant='h4' gutterBottom>
                  {user?.name}
                </Typography>
                <Card
                  sx={{
                    p: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    maxHeight: '32.5rem',
                    minWidth: '60vw',
                  }}
                >
                  <Stack
                    direction='column'
                    alignItems='left'
                    justifyContent='left'
                    // mt={2}
                  >
                    <Typography variant='body1' gutterBottom>
                      <b>Email: </b> {user?.email}
                    </Typography>
                    <Typography variant='body1' gutterBottom>
                      <b> Phone: </b>
                      {user?.phone ? user?.phone : 'not available'}
                    </Typography>
                    <Typography variant='body1' gutterBottom>
                      <b>Role: </b>
                      {user?.role}
                    </Typography>
                    <Typography variant='body1' gutterBottom>
                      <b>Activated: </b>
                      {user?.activated == false ? ' no.' : ' yes.'}
                    </Typography>
                    <Typography variant='body1' gutterBottom>
                      <b>Test user: </b>
                      {user?.testing == false ? ' no.' : ' yes.'}
                    </Typography>
                    {/* registration date */}
                    <Typography variant='body1' gutterBottom>
                      <b>Registration: </b>
                      {user?.createdAt
                        ? format(
                            new Date(user.createdAt),
                            'yyyy-MM-dd, HH:mm:ss'
                          )
                        : 'N/A'}
                    </Typography>
                    <Typography variant='body1' gutterBottom>
                      <b>Updated: </b>
                      {user?.updatedAt
                        ? format(
                            new Date(user.updatedAt),
                            'yyyy-MM-dd, HH:mm:ss'
                          )
                        : 'N/A'}
                    </Typography>
                  </Stack>
                  <Stack direction='row' justifyContent='space-between' mt={2}>
                    <Button
                      startIcon={<Iconify icon='bx:edit' />}
                      color='info'
                      component={RouterLink}
                      to={`/dashboard/user/${user?._id}/edit`}
                      variant='contained'
                    >
                      Edit
                    </Button>
                    <Button
                      startIcon={<Iconify icon='bx:bx-trash' />}
                      variant='contained'
                      color='error'
                      onClick={() => setOpenRemoveUserDialog(true)}
                    >
                      Delete
                    </Button>
                  </Stack>
                </Card>
              </Grid>
            </Grid>
          </Container>
          {openRemoveUserDialog && (
            <ConfirmationDialog
              onConfirm={(inputText) => handleRemoveUser(inputText)}
              onCancel={() => setOpenRemoveUserDialog(false)}
              title={`Are you sure you want to delete ${user?.name} ?`}
              description={
                'This action will delete the user from the system. This action cannot be undone.'
              }
            />
          )}
        </Page>
      )}
    </>
  );
}
