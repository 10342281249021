import React, { useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Stack,
  IconButton,
} from '@mui/material';

import CloseIcon from '@mui/icons-material/Close'; // Import CloseIcon
const AlertDialog = ({ title, description, onConfirm, onCancel }) => {
  const [open, setOpen] = useState(true);

  const handleClose = () => {
    setOpen(false);
    if (onCancel) {
      onCancel();
    }
  };

  return (
    <div>
      <Dialog
        sx={{ '& .MuiDialog-paper': { width: '80%', maxHeight: 435 } }}
        open={open}
        onClose={handleClose}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle id='alert-dialog-title'>
          {title}
          <IconButton
            edge='end'
            color='inherit'
            onClick={handleClose}
            aria-label='close'
            sx={{
              position: 'absolute',
              right: 15,
              top: 8,
              padding: 0,
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-description'>
            {description}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Stack direction='row' spacing={3}>
            <Button
              onClick={onCancel ? onCancel : handleClose}
              color='info'
              variant='contained'
            >
              Cancel
            </Button>
            <Button
              onClick={onConfirm ? onConfirm : handleClose}
              color='error'
              variant='contained'
              autoFocus
            >
              Confirm
            </Button>
          </Stack>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default AlertDialog;
