import { useNavigate } from 'react-router-dom';
import { Link as RouterLink } from 'react-router-dom';
import { useContext, useState } from 'react';
import { filter } from 'lodash';
// material
import {
  Card,
  Table,
  Stack,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  Skeleton,
  Link,
  Breadcrumbs,
  Tooltip,
} from '@mui/material';

// components
import Page from '../../../components/Page';
import Label from '../../../components/Label';
import Scrollbar from '../../../components/Scrollbar';
import SearchNotFound from '../../../components/SearchNotFound';
import {
  SoftwareListHead,
  SoftwareListToolbar,
} from '../../../components/_dashboard/software';

//
import { SoftwaresContext } from 'src/Contexts/SoftwaresContext';
import { v4 } from 'uuid';

// import format for date
import format from 'date-fns/format';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  {
    _id: v4(),
    id: 'version',
    label: 'Version',
    alignRight: false,
  },
  {
    _id: v4(),
    id: 'softwareType',
    label: 'Software type',
    alignRight: false,
  },
  {
    _id: v4(),
    id: 'ubuntuVersion',
    label: 'Ubuntu version',
    alignRight: false,
  },
  {
    _id: v4(),
    id: 'softwarePath',
    label: 'Released software path',
    alignRight: false,
  },

  {
    _id: v4(),
    id: 'createdAt',
    label: 'Installation date',
    alignRight: false,
  },
  {
    _id: v4(),
    id: 'recalled',
    label: 'Active version',
    alignRight: false,
  },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array = [], comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(
      array,
      (_softwares) =>
        _softwares.version.toLowerCase().indexOf(query.toLowerCase()) !== -1
    );
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function ReleasedSoftwares() {
  const { softwares, loading } = useContext(SoftwaresContext);
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('desc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('createdAt');
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const navigate = useNavigate();

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  const emptyRows =
    page > 0
      ? Math.max(0, (1 + page) * rowsPerPage - (softwares?.length || 0))
      : 0;

  const filteredSoftwares = applySortFilter(
    softwares,
    getComparator(order, orderBy),
    filterName
  );

  const isSoftwareNotFound = filteredSoftwares.length === 0;

  return (
    <Page title='Released softwares | Robin Radar Systems Admin'>
      <Breadcrumbs aria-label='breadcrumb'>
        <Link component={RouterLink} to='/' underline='hover' color='inherit'>
          Home
        </Link>
        <Typography color='text.primary'>Released softwares</Typography>
      </Breadcrumbs>
      <Container>
        <Stack
          direction='row'
          alignItems='center'
          justifyContent='space-between'
          mb={5}
        >
          <Typography variant='h4' gutterBottom>
            Released softwares
          </Typography>
        </Stack>

        <Card>
          <SoftwareListToolbar
            numSelected={selected.length}
            filterName={filterName}
            onFilterName={handleFilterByName}
            slug='for version...'
          />

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <SoftwareListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={softwares?.length || 0}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  noCheckBox
                />
                <TableBody>
                  {loading
                    ? Array(5)
                        .fill()
                        .map(() => {
                          return (
                            <TableRow
                              hover
                              key={v4()}
                              tabIndex={-1}
                              role='checkbox'
                              sx={{
                                cursor: 'pointer',
                                textDecoration: 'none',
                              }}
                            >
                              {Array(9)
                                .fill()
                                .map(() => (
                                  <TableCell key={v4()} align='right'>
                                    <Skeleton />
                                  </TableCell>
                                ))}
                            </TableRow>
                          );
                        })
                    : filteredSoftwares

                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map((row) => {
                          // ROW variables
                          const {
                            _id,
                            version,
                            softwarePath,
                            recalled,
                            softwareType,
                            // createdAt,
                            ubuntuVersion,
                          } = row;

                          const isItemSelected =
                            selected.indexOf(version) !== -1;

                          return (
                            <TableRow
                              hover
                              key={_id}
                              tabIndex={-1}
                              role='checkbox'
                              selected={isItemSelected}
                              aria-checked={isItemSelected}
                              sx={{
                                cursor: 'pointer',
                                textDecoration: 'none',
                              }}
                              onClick={() =>
                                navigate(`/dashboard/softwares/${row._id}`)
                              }
                            >
                              <TableCell padding='checkbox'></TableCell>
                              <TableCell align='left'>{version}</TableCell>
                              <TableCell align='left'>{softwareType}</TableCell>
                              <TableCell align='left'>
                                {ubuntuVersion?.length
                                  ? ubuntuVersion.join(', ')
                                  : ' - '}
                              </TableCell>
                              <TableCell align='left'>
                                {softwarePath && softwarePath.length > 0 ? (
                                  <Tooltip
                                    title={softwarePath}
                                    placement='top-start'
                                    arrow
                                  >
                                    <span>
                                      {softwarePath.length > 15
                                        ? '...' + softwarePath.slice(-15)
                                        : softwarePath}
                                    </span>
                                  </Tooltip>
                                ) : (
                                  'Not found.'
                                )}
                              </TableCell>
                              <TableCell align='left'>
                                {format(
                                  new Date(row.createdAt),
                                  'yyyy-MM-dd, HH:mm:ss'
                                ) || ' - '}
                              </TableCell>
                              <TableCell align='left'>
                                <Label color={recalled ? 'error' : 'success'}>
                                  {recalled ? 'No' : 'Yes'}
                                </Label>
                              </TableCell>
                            </TableRow>
                          );
                        })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
                {isSoftwareNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell
                        align='center'
                        colSpan={TABLE_HEAD.length + 1}
                        sx={{ py: 3 }}
                      >
                        <SearchNotFound searchQuery={filterName} />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25, 50, 100]}
            component='div'
            count={softwares?.length || 0}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>
    </Page>
  );
}
