import { useArray } from 'src/hooks';
import React, { useState, useEffect, useContext } from 'react';
import { toast } from 'react-toastify';
import { makeReq, handleCatch } from 'src/utils/makeReq';
import { AuthContext } from './AuthContext';
import { useNavigate } from 'react-router-dom';

export const LogsContext = React.createContext();

export const LogsProvider = ({ children }) => {
  const [loading, setLoading] = useState(true);
  const { user } = useContext(AuthContext);
  const navigate = useNavigate();
  const [logs, setLogs, pushLog, filterLog, updateLog, removeLog, clearLogs] =
    useArray([], '_id');

  const fetchLogs = async () => {
    try {
      const resData = await makeReq(`/logs`);
      setLogs(resData.logs);
    } catch (err) {
      handleCatch(err);
    } finally {
      setLoading(false);
    }
  };

  const getLogById = (id) => {
    return logs.find((el) => el._id === id);
  };

  useEffect(() => {
    if (!user) return;
    fetchLogs();
  }, [user]);

  // * CRUD Operations
  const deleteAllLogs = async () => {
    try {
      await makeReq(`/logs`, {}, 'DELETE');
      toast.success('All logs have been deleted successfully!');
      clearLogs();
    } catch (err) {
      handleCatch(err);
    }
  };

  return (
    <LogsContext.Provider
      displayName='Radars Context'
      value={{
        loading,
        logs,
        getLogById,
        deleteAllLogs,
      }}
    >
      {children}
    </LogsContext.Provider>
  );
};
