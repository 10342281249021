import { Link as RouterLink } from 'react-router-dom';
import {
  Stack,
  Container,
  Typography,
  Skeleton,
  Card,
  Link,
} from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router';
import NotFound from '../Page404';
import Page from '../../components/Page';
import { LogsContext } from 'src/Contexts/LogsContext';
import { toast } from 'react-toastify';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import { format } from 'date-fns';

//

export default function LogDetails() {
  const { logs, getLogById, loading } = useContext(LogsContext);
  const { id } = useParams();

  const [log, setLog] = useState();
  const [logNotFound, setLogNotFound] = useState(false);

  useEffect(() => {
    if (!id) return;
    if (loading) return;

    let el = getLogById(id);

    if (!el) {
      setLogNotFound(true);
      toast.error("The log with this id doesn't exist.");
    } else {
      setLog({ ...el });
    }
  }, [getLogById, logs, loading, id]);

  return (
    <>
      {logNotFound || loading ? (
        <Page title='404 | Not Found'>
          {loading ? (
            <Skeleton variant='rectangular' height='600px' />
          ) : (
            <NotFound />
          )}
        </Page>
      ) : (
        <Page title={log?.method + ' | Robin Radar Systems Admin'}>
          <Breadcrumbs aria-label='breadcrumb'>
            <Link
              component={RouterLink}
              to='/'
              underline='hover'
              color='inherit'
            >
              Home
            </Link>
            <Link
              component={RouterLink}
              to='/dashboard/logs'
              underline='hover'
              color='inherit'
            >
              Logs
            </Link>
            <Typography color='text.primary'>{log?._id}</Typography>
          </Breadcrumbs>
          <Container>
            <Stack
              direction='row'
              alignItems='center'
              justifyContent='space-between'
              mb={5}
            >
              <Typography variant='h4' gutterBottom>
                Log ({log?._id})
              </Typography>
            </Stack>
            <Card
              sx={{
                p: 2,
                display: 'flex',
                flexDirection: 'column',
                height: '100%',
                minWidth: 300,
                maxWidth: '32.5rem',
              }}
            >
              <Stack direction='column' alignItems='left' justifyContent='left'>
                <Typography variant='body1' gutterBottom>
                  <b>Method: </b> {log?.method}
                </Typography>
                <Typography variant='body1' gutterBottom>
                  <b> URL: </b>
                  {log?.url}
                </Typography>
                <Typography variant='body1' gutterBottom>
                  <b>Status: </b>
                  {log?.status}
                </Typography>
                <Typography variant='body1' gutterBottom>
                  <b>Response duration: </b>
                  {log?.responseTime} ms
                </Typography>

                <Typography variant='body1' gutterBottom>
                  <b>Request time: </b>
                  {log?.createdAt
                    ? format(new Date(log.createdAt), 'yyyy-MM-dd, HH:mm:ss')
                    : 'N/A'}
                </Typography>

                <Typography variant='body1' gutterBottom>
                  <b>User ID: </b>
                  {log?.user?._id || 'N/A (login request)'}
                </Typography>

                <Typography variant='body1' gutterBottom>
                  <b>User name: </b>
                  {log?.user?.name || 'N/A (login request)'}
                </Typography>
              </Stack>
            </Card>
          </Container>
        </Page>
      )}
    </>
  );
}
