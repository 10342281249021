import * as React from 'react';
import {
  Stack,
  Container,
  Typography,
  Card,
  Button,
  Tooltip,
} from '@mui/material';

//
import Iconify from '../../components/Iconify';

import { useContext, useEffect, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import Link from '@mui/material/Link';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Page from '../../components/Page';
import { format } from 'date-fns';

// Contexts
import { RadarsContext } from 'src/Contexts/RadarsContext';
import { UploadedVersionContext } from 'src/Contexts/UploadedVersionContext';

// Providers
const defaultAppInfo = {
  lastUpdated: process.env.REACT_APP_BUILD_TIME || 'Not Available',
  repository: 'https://bitbucket.org/robin-radar-systems/sd-api/src/',
  confulence_URL:
    'https://robinradar.atlassian.net/wiki/spaces/DEV/pages/284033095/Software+Deployment+API',
  install_sd_download_URL:
    'https://robinradar.atlassian.net/wiki/download/attachments/284033095/install_sd_download.sh?api=v2',
};

export default function InfoPage() {
  const [appInfo, setAppInfo] = useState(defaultAppInfo);

  const { downloadEmptyRadarConfigFile } = useContext(RadarsContext);

  const { downloadUploadConfigFile } = useContext(UploadedVersionContext);

  // Get download config file
  const handleDownloadConfigFile = (e) => {
    e.preventDefault();
    downloadEmptyRadarConfigFile();
  };

  // Get upload config file
  const handleUploadConfigFile = (e) => {
    e.preventDefault();
    downloadUploadConfigFile();
  };

  // Set app info
  useEffect(() => {
    setAppInfo({
      ...defaultAppInfo,
      lastUpdated: process.env.REACT_APP_BUILD_TIME || 'Not Available',
    });
  }, []);

  return (
    <Page title='Info | Robin Radar Systems Admin'>
      <Breadcrumbs aria-label='breadcrumb'>
        <Link component={RouterLink} to='/' underline='hover' color='inherit'>
          Home
        </Link>
        <Typography color='text.primary'>Info</Typography>
      </Breadcrumbs>
      <Container>
        <Stack
          direction='row'
          alignItems='center'
          justifyContent='space-between'
          mb={5}
        >
          <Typography variant='h4' gutterBottom>
            Info
          </Typography>
        </Stack>
        <Stack spacing={2}>
          <Card
            sx={{
              p: 2,
              display: 'flex',
              flexDirection: 'column',
              height: '100%',
            }}
          >
            <Typography variant='h6'>
              Updated on:{' '}
              <Typography color='text.secondary' component='span'>
                {appInfo?.lastUpdated && !isNaN(new Date(appInfo.lastUpdated))
                  ? format(
                      new Date(appInfo.lastUpdated),
                      'yyyy-MM-dd, HH:mm:ss'
                    )
                  : 'Not Available'}
              </Typography>
            </Typography>
          </Card>
          <Card
            sx={{
              p: 2,
              display: 'flex',
              flexDirection: 'column',
              height: '100%',
            }}
          >
            <Typography variant='h6'>
              Project Bitbucket repository:{' '}
              <Link
                href={appInfo.repository}
                color='text.secondary'
                target='_blank'
              >
                {appInfo.repository}
              </Link>
            </Typography>
          </Card>
          <Card
            sx={{
              p: 2,
              display: 'flex',
              flexDirection: 'column',
              height: '100%',
            }}
          >
            <Typography variant='h6'>
              S.D. Confluence page:{' '}
              <Link
                href={appInfo.confulence_URL}
                color='text.secondary'
                target='_blank'
              >
                {appInfo.confulence_URL}
              </Link>
            </Typography>
          </Card>
          <Card
            sx={{
              p: 2,
              display: 'flex',
              flexDirection: 'column',
              height: '100%',
            }}
          >
            <Typography variant='h6'>
              Installation script robin-sd-download package:{' '}
              <Link
                href={appInfo.install_sd_download_URL}
                color='text.secondary'
                target='_blank'
              >
                {appInfo.install_sd_download_URL}
              </Link>
            </Typography>
          </Card>
          <Card
            sx={{
              p: 2,
              display: 'flex',
              flexDirection: 'column',
              height: '100%',
            }}
          >
            <Typography variant='h6'>Config files</Typography>
            <Typography variant='body1' gutterBottom>
              After downloading the config file, make sure to fill the content
              with the correct values.
            </Typography>
            <Stack
              direction='row'
              alignItems='flex-start'
              justifyContent='space-between'
              gap='1rem'
            >
              <Tooltip
                title='Download the config file for the robin-sd-upload package. Complete it with your own details before use.'
                placement='bottom-end'
                arrow
                enterDelay={500}
                leaveDelay={200}
              >
                <Button
                  variant='contained'
                  style={{ textTransform: 'none' }}
                  color='primary'
                  onClick={handleUploadConfigFile}
                  startIcon={<Iconify icon='bx:bx-download' />}
                >
                  Config for upload
                </Button>
              </Tooltip>
              <Tooltip
                title='Download the config file for the robin-sd-download package. Complete it with your own details before use.'
                placement='bottom-end'
                arrow
                enterDelay={500}
                leaveDelay={200}
              >
                <Button
                  variant='contained'
                  style={{ textTransform: 'none' }}
                  color='primary'
                  onClick={handleDownloadConfigFile}
                  startIcon={<Iconify icon='bx:bx-download' />}
                >
                  Config for download
                </Button>
              </Tooltip>
            </Stack>
          </Card>
        </Stack>
      </Container>
    </Page>
  );
}
