import { Navigate, useRoutes } from 'react-router-dom';
import { useContext, useEffect, useState } from 'react';

// layouts
import DashboardLayout from './layouts/dashboard';

// components
import Loader from './components/Loader';
import Logout from './components/Logout';

//
import Login from './pages/Login';
import DashboardApp from './pages/DashboardApp';
import User from './pages/Users';
import NewUser from './pages/Users/new';
import DeletedUsers from './pages/Users/deleted';
import DeletedUserDetails from './pages/Users/deleted/details';
import UserDetails from './pages/Users/details';
import EditUser from './pages/Users/edit';
import Account from './pages/Account';
import Radars from './pages/Radars';
import RadarDetails from './pages/Radars/details/details';
import RadarEdit from './pages/Radars/details/edit';
import CreateRadar from './pages/Radars/create';
import DeletedRadars from './pages/Radars/deleted';
import DeletedRadarDetails from './pages/Radars/deleted/details';
import UploadedVersions from './pages/Softwares/uploaded';
import UploadedVersionsDetails from './pages/Softwares/uploaded/details';
import ReleasedSoftwares from './pages/Softwares/released';
import ReleasedSoftwareDetails from './pages/Softwares/released/details';
import Logs from './pages/LogPages/index';
import LogDetails from './pages/LogPages/details';
import DeletedSoftwares from './pages/Softwares/deleted';
import DeletedSoftwareDetails from './pages/Softwares/deleted/details';
import InfoPage from './pages/Info';
// import NotFound from './pages/Page404';

// contexts
import { AuthContext } from './Contexts/AuthContext';

// ----------------------------------------------------------------------

export default function Router() {
  const { token, user } = useContext(AuthContext);
  const protectedRoutes = [
    {
      path: '/dashboard',
      element: <DashboardLayout />,
      children: [
        { element: <Navigate to='/dashboard/app' replace /> },
        { path: 'app', element: <DashboardApp /> },
        { path: 'user/list', element: <User /> },
        { path: 'user/new', element: <NewUser /> },
        { path: 'user/deleted', element: <DeletedUsers /> },
        { path: 'user/deleted/:id', element: <DeletedUserDetails /> },
        { path: 'user/:id', element: <UserDetails /> },
        { path: 'user/:id/edit', element: <EditUser /> },
        { path: 'account', element: <Account /> },
        { path: 'radars/list', element: <Radars /> },
        { path: 'radars/create', element: <CreateRadar /> },
        { path: 'radars/deleted', element: <DeletedRadars /> },
        { path: 'radars/deleted/:id', element: <DeletedRadarDetails /> },
        { path: 'radars/:id', element: <RadarDetails /> },
        { path: 'radars/:id/edit', element: <RadarEdit /> },
        { path: 'softwares/uploaded', element: <UploadedVersions /> },
        {
          path: 'uploaded/:id',
          element: <UploadedVersionsDetails />,
        },
        { path: 'softwares/backups', element: <DeletedSoftwares /> },
        { path: 'backups/:id', element: <DeletedSoftwareDetails /> },
        { path: 'softwares/installed', element: <ReleasedSoftwares /> },
        { path: 'softwares/:id', element: <ReleasedSoftwareDetails /> },
        { path: 'info', element: <InfoPage /> },
        { path: 'logs', element: <Logs /> },
        { path: 'logs/:id', element: <LogDetails /> },
      ],
    },
    { path: 'logout', element: <Logout /> },
    { path: '*', element: <Navigate to='/dashboard/app' replace /> },
  ];

  const publicRoutes = [
    { path: 'login', element: <Login /> },
    { path: '*', element: <Navigate to='/login' /> },
  ];

  const loaderRoute = [
    {
      path: '*',
      element: <Loader />,
    },
  ];

  const [routes, setRoutes] = useState(loaderRoute);

  useEffect(() => {
    if (token) {
      if (user) setRoutes(protectedRoutes);
      else setRoutes(loaderRoute);
    } else setRoutes(publicRoutes);
  }, [token, user]);

  return useRoutes(routes);
}
