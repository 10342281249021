import React, { useState } from 'react';
import { format } from 'date-fns';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Stack,
  List,
  ListItem,
  ListItemText,
  Typography,
  TextField,
  IconButton, // Import IconButton
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close'; // Import CloseIcon
import { red } from '@mui/material/colors'; // import red color

const InfoDialog = ({ title, description, data, onConfirm, onCancel }) => {
  const [open, setOpen] = useState(true);
  const [inputText, setInputText] = useState('');

  const handleClose = () => {
    setOpen(false);
    if (onCancel) {
      onCancel();
    }
  };

  const handleConfirm = () => {
    setOpen(false);
    if (onConfirm) {
      onConfirm(inputText);
    }
  };

  const dataList = data.radars ? data.radars : [];

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby='info-dialog-title'
        aria-describedby='info-dialog-description'
      >
        <DialogTitle id='info-dialog-title'>
          {title}
          <IconButton
            edge='end'
            color='inherit'
            onClick={handleClose}
            aria-label='close'
            sx={{
              position: 'absolute',
              right: 15,
              top: 8,
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id='info-dialog-description'>
            {description}
          </DialogContentText>
          <List>
            {dataList.length > 0 ? (
              dataList.map((item) => (
                <ListItem
                  key={item.id}
                  sx={{
                    bgcolor: red[50],
                    borderRadius: 1,
                    mb: 1,
                    mt: 1,
                  }}
                >
                  <ListItemText
                    primary={
                      <>
                        <Typography variant='body1'>{`Customer name: ${
                          item.customer ? item.customer : 'N/A'
                        }`}</Typography>
                        <Typography
                          variant='body2'
                          color='text.secondary'
                          paragraph
                        >
                          {`Radar alias: ${item.alias ? item.alias : 'N/A'}`}
                        </Typography>
                      </>
                    }
                    secondary={
                      <Typography variant='body2' color='text.secondary'>
                        {`Radar ID: ${item.id ? item.id : 'N/A'}`}
                        <br />
                        {`Created at: ${
                          item.createdAt
                            ? format(
                                new Date(item.createdAt),
                                'yyyy-MM-dd, HH:mm:ss'
                              )
                            : 'N/A'
                        }`}
                      </Typography>
                    }
                  />
                </ListItem>
              ))
            ) : (
              <Typography
                variant='body1'
                color='text.secondary'
                align='center'
                sx={{ mt: 2 }}
              >
                This version is not in use by any radars.
              </Typography>
            )}
            <TextField
              margin='dense'
              id='name'
              label='Please type the reason for recalling... (max 150 characters)'
              type='text'
              fullWidth
              inputProps={{ maxLength: 150 }}
              value={inputText}
              onChange={(event) => setInputText(event.target.value)}
            />
          </List>
        </DialogContent>
        <DialogActions>
          <Stack direction='row' spacing={3}>
            <Button
              onClick={onCancel ? onCancel : handleClose}
              color='info'
              variant='contained'
            >
              Cancel
            </Button>
            <Button
              onClick={handleConfirm}
              color='error'
              variant='contained'
              autoFocus
              disabled={inputText.trim() === ''}
            >
              Confirm
            </Button>
          </Stack>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default InfoDialog;
