import { useContext, useState } from 'react';
import { useNavigate, Link as RouterLink } from 'react-router-dom';

// other
import { faker } from '@faker-js/faker';
import { filter } from 'lodash';
import { v4 } from 'uuid';
import { format } from 'date-fns';

// material
import {
  Card,
  Popover,
  MenuItem,
  Table,
  Stack,
  Avatar,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  Skeleton,
  IconButton,
  Link,
  Breadcrumbs,
  Checkbox,
} from '@mui/material';

// components
import Label from '../../components/Label.js';
import Iconify from '../../components/Iconify';
import Page from '../../components/Page';
import Scrollbar from '../../components/Scrollbar';
import SearchNotFound from '../../components/SearchNotFound';
import {
  UserListHead,
  UserListToolbar,
} from '../../components/_dashboard/user';
import AlertDialog from 'src/components/dialogs/AlertDialog';
import ConfirmationDialog from 'src/components/dialogs/ConfirmationDialog';

// Contexts
import { UsersContext } from 'src/Contexts/UsersContext';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { _id: v4(), id: 'checkbox', label: '', alignRight: false },
  { _id: v4(), id: 'name', label: 'Name', alignRight: false },
  { _id: v4(), id: 'email', label: 'Email', alignRight: false },
  { _id: v4(), id: 'role', label: 'Role', alignRight: false },
  { _id: v4(), id: 'createdAt', label: 'Registration', alignRight: false },
  { _id: v4(), id: 'activated', label: 'Status', alignRight: false },
  { _id: v4(), id: 'morevert', label: '', alignRight: false },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (typeof a[orderBy] === 'string' && typeof b[orderBy] === 'string') {
    if (b[orderBy].toLowerCase() < a[orderBy].toLowerCase()) {
      return -1;
    }
    if (b[orderBy].toLowerCase() > a[orderBy].toLowerCase()) {
      return 1;
    }
    return 0;
  } else {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(
  array = [],
  comparator,
  query = '',
  roles = [],
  selectedDropdownFilter
) {
  const stabilizedThis = array.map((el, index) => [el, index]);

  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });

  let result = stabilizedThis.map((el) => el[0]);

  // Filtering based on the query (name or email)
  if (query) {
    result = filter(
      result,
      (_user) =>
        _user.name.toLowerCase().includes(query.toLowerCase()) ||
        _user.email.toLowerCase().includes(query.toLowerCase())
    );
  }

  // Filtering based on roles
  if (roles.length) {
    result = filter(result, (_user) => roles.includes(_user.role));
  }

  // Filtering based on selectedDropdownFilter
  if (selectedDropdownFilter === 'hide-test') {
    result = filter(result, (_user) => !_user.testing);
  } else if (selectedDropdownFilter === 'show-test') {
    result = filter(result, (_user) => _user.testing);
  }

  return result;
}

export default function User() {
  // Contexts
  const { users, loading, deleteUser, editUser, softDeleteMultipleUsers } =
    useContext(UsersContext);

  // State
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('name');
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [open, setOpen] = useState(null);
  const [selectedUser, setSelectedUser] = useState(null);
  const [selectedRoles, setSelectedRoles] = useState([]);
  const [selectedDropdownFilter, setSelectedDropdownFilter] =
    useState('hide-test');
  const [openUserDeleteDialog, setOpenUserDeleteDialog] = useState(false);
  const [openUserStatusDialog, setOpenUserStatusDialog] = useState(false);
  const [openUserMarkTestDialog, setOpenUserMarkTestDialog] = useState(false);
  const [openDeleteAllSelectedUserDialog, setOpenDeleteAllSelectedUserDialog] =
    useState(false);

  const navigate = useNavigate();

  // Handle functions
  const handleOpenMenu = (event, row) => {
    event.stopPropagation();
    setSelectedUser(row);
    setOpen(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setOpen(null);
    setSelectedUser(null);
  };

  const handleMarkTestUser = (event, id) => {
    event.stopPropagation();
    selectedUser.testing = !selectedUser.testing;
    editUser(selectedUser._id, selectedUser);
    setOpenUserMarkTestDialog(false);
    handleCloseMenu();
  };

  const handleStatusUser = (event, id) => {
    event.stopPropagation();
    selectedUser.activated = !selectedUser.activated;
    editUser(selectedUser._id, selectedUser);
    setOpenUserStatusDialog(false);
    handleCloseMenu();
  };

  const handleDeleteUser = (inputText) => {
    deleteUser(selectedUser._id, inputText);
    setOpenUserDeleteDialog(false);
    handleCloseMenu();
  };

  const handleEditUser = (event) => {
    event.stopPropagation();
    navigate(`/dashboard/user/${selectedUser._id}/edit`);
    handleCloseMenu();
  };

  const handleSelectedTrashClick = (inputText) => {
    softDeleteMultipleUsers(selected, inputText);
    setOpenDeleteAllSelectedUserDialog(false);
    setSelected([]);
  };

  const handleRoleChange = (event) => {
    setPage(0);
    setSelectedRoles(event.target.value);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = () => {
    if (selected.length === filteredUsers.length) {
      setSelected([]);
    } else {
      setSelected(filteredUsers.map((user) => user._id));
    }
  };

  const handleClick = (event, _id) => {
    const selectedIndex = selected.indexOf(_id);

    if (selectedIndex === -1) {
      // If not currently selected, add to the selection.
      setSelected((prevSelected) => [...prevSelected, _id]);
    } else {
      // If currently selected, remove from the selection.
      setSelected((prevSelected) => prevSelected.filter((id) => id !== _id));
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  const handleDropdownFilterChange = (event) => {
    setPage(0);
    setSelectedDropdownFilter(event.target.value);
  };

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - (users?.length || 0)) : 0;

  const filteredUsers = applySortFilter(
    users,
    getComparator(order, orderBy),
    filterName,
    selectedRoles,
    selectedDropdownFilter
  );

  const isUserNotFound = filteredUsers.length === 0;

  return (
    <Page title='Users | Robin Radar Systems Admin '>
      <Breadcrumbs aria-label='breadcrumb'>
        <Link component={RouterLink} to='/' underline='hover' color='inherit'>
          Home
        </Link>
        <Typography color='text.primary'>Users</Typography>
      </Breadcrumbs>
      <Container>
        <Stack
          direction='row'
          alignItems='center'
          justifyContent='space-between'
          mb={5}
        >
          <Typography variant='h4' gutterBottom>
            Users
          </Typography>
        </Stack>

        <Card>
          <UserListToolbar
            numSelected={selected.length}
            filterName={filterName}
            onFilterName={handleFilterByName}
            slug='for name or email...'
            selectedDropdownFilter={selectedDropdownFilter}
            handleDropdownFilterChange={handleDropdownFilterChange}
            navigate={navigate}
            onTrashClick={() => setOpenDeleteAllSelectedUserDialog(true)}
            selectedRoles={selectedRoles}
            handleRoleChange={handleRoleChange}
            showCreateButton={true}
          />
          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <UserListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={users?.length || 0}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                />
                <TableBody>
                  {loading
                    ? Array(5)
                        .fill()
                        .map(() => {
                          return (
                            <TableRow
                              hover
                              key={v4()}
                              tabIndex={-1}
                              role='checkbox'
                              sx={{ cursor: 'pointer', textDecoration: 'none' }}
                            >
                              {Array(9)
                                .fill()
                                .map(() => (
                                  <TableCell key={v4()} align='right'>
                                    <Skeleton />
                                  </TableCell>
                                ))}
                            </TableRow>
                          );
                        })
                    : filteredUsers
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map((row) => {
                          const {
                            _id,
                            name,
                            role,
                            activated,
                            email,
                            createdAt,
                          } = row;

                          const isItemSelected = selected.indexOf(_id) !== -1;

                          return (
                            <TableRow
                              hover
                              key={_id}
                              tabIndex={-1}
                              role='checkbox'
                              selected={isItemSelected}
                              aria-checked={isItemSelected}
                              sx={{
                                cursor: 'pointer',
                                textDecoration: 'none',
                              }}
                              onClick={() =>
                                navigate(`/dashboard/user/${row._id}`)
                              }
                            >
                              <TableCell
                                padding='checkbox'
                                onClick={(event) => {
                                  event.stopPropagation();
                                }}
                                sx={{
                                  cursor: 'default',
                                  textDecoration: 'none',
                                }}
                              >
                                <Checkbox
                                  checked={selected.includes(_id)} // This checks if the user's _id is in the selected array
                                  onClick={(event) => {
                                    event.stopPropagation(); // Prevent event from reaching the TableRow
                                    handleClick(event, _id);
                                  }}
                                />
                              </TableCell>
                              <TableCell padding='none'></TableCell>
                              <TableCell
                                component='th'
                                scope='row'
                                padding='none'
                              >
                                <Stack
                                  direction='row'
                                  alignItems='center'
                                  spacing={2}
                                >
                                  <Avatar
                                    alt={name}
                                    src={`${faker.internet.avatar}`}
                                  />
                                  <Typography variant='subtitle2' noWrap>
                                    {name}
                                  </Typography>
                                </Stack>
                              </TableCell>
                              <TableCell align='left'>{email}</TableCell>
                              <TableCell align='left'>{role}</TableCell>
                              <TableCell align='left'>
                                {format(
                                  new Date(createdAt),
                                  'yyyy-MM-dd, HH:mm:ss'
                                )}
                              </TableCell>
                              <TableCell align='left'>
                                <Label
                                  color={
                                    (activated.toString() == 'false' &&
                                      'error') ||
                                    'success'
                                  }
                                >
                                  {activated.toString() == 'true'
                                    ? ' Activated'
                                    : ' Not activated'}
                                </Label>
                              </TableCell>
                              {/* Edit user: deactivate, remove */}
                              <TableCell
                                sx={{
                                  cursor: 'default',
                                  textDecoration: 'none',
                                }}
                                align='right'
                                onClick={(event) => {
                                  event.stopPropagation();
                                }}
                              >
                                <IconButton
                                  size='small'
                                  color='inherit'
                                  onClick={(event) => {
                                    handleOpenMenu(event, row);
                                  }}
                                >
                                  <Iconify icon={'eva:more-vertical-fill'} />
                                </IconButton>
                              </TableCell>
                            </TableRow>
                          );
                        })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
                {isUserNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell
                        align='center'
                        colSpan={TABLE_HEAD.length}
                        sx={{ py: 3 }}
                      >
                        <SearchNotFound searchQuery={filterName} />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25, 50, 100]}
            component='div'
            count={users?.length || 0}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>
      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleCloseMenu}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 1,
            width: 220,
            '& .MuiMenuItem-root': {
              px: 1,
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
        <MenuItem onClick={handleEditUser}>
          <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />
          Modify
        </MenuItem>
        <MenuItem onClick={() => setOpenUserMarkTestDialog(true)}>
          <Iconify icon={'eva:checkmark-circle-2-fill'} sx={{ mr: 2 }} />
          {selectedUser?.testing
            ? 'Mark as NOT test user'
            : 'Mark as TEST user'}
        </MenuItem>
        <MenuItem onClick={() => setOpenUserStatusDialog(true)}>
          <Iconify icon={'eva:flip-2-fill'} sx={{ mr: 2 }} />
          {selectedUser?.activated ? 'Deactivate user' : 'Activate user'}
        </MenuItem>
        <MenuItem
          onClick={() => setOpenUserDeleteDialog(true)}
          sx={{ color: 'error.main' }}
        >
          <Iconify icon={'eva:trash-2-outline'} sx={{ mr: 2 }} />
          Delete
        </MenuItem>
      </Popover>
      {openUserMarkTestDialog && (
        <AlertDialog
          onConfirm={handleMarkTestUser}
          onCancel={() => setOpenUserMarkTestDialog(false)}
          title={`Are you sure you want to mark ${selectedUser.name} as test user?`}
          description={
            'This action will mark the user as test user, the user will be removed from the list of user.'
          }
        />
      )}
      {openUserStatusDialog && (
        <AlertDialog
          onConfirm={handleStatusUser}
          onCancel={() => setOpenUserStatusDialog(false)}
          title={`Are you sure you want to edit ${selectedUser.name}'s status?`}
          description={
            'This action will edit the user status to opposite. The user will not be able to login or access the system if the status is deactivated.'
          }
        />
      )}
      {openUserDeleteDialog && (
        <ConfirmationDialog
          onConfirm={(inputText) => handleDeleteUser(inputText)}
          onCancel={() => setOpenUserDeleteDialog(false)}
          title={`Are you sure you want to delete ${selectedUser.name}?`}
          description={
            'This action will delete the user from the system. This action cannot be undone.'
          }
        />
      )}
      {openDeleteAllSelectedUserDialog && (
        <ConfirmationDialog
          title={
            <>
              Are you sure you want to delete all selected users (
              <span style={{ color: 'red' }}>{selected.length}</span> radars)?
            </>
          }
          description={
            <>
              This action will delete all selected users (
              <span style={{ color: 'red' }}>{selected.length}</span> users)
              from the system. The users will be permanently deleted after a
              period of time. Please note that, the deleted users cannot be used
              for any purpose. You can only delete users created by you. If you
              want to delete users created by another user, please contact
              someone (admin) who has the permission to do.
            </>
          }
          onConfirm={(inputText) => handleSelectedTrashClick(inputText)}
          onCancel={() => setOpenDeleteAllSelectedUserDialog(false)}
        />
      )}
    </Page>
  );
}
