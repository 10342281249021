import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { makeReq, handleCatch } from '../utils/makeReq';

export const LOCALSTORAGE_TOKEN_KEY = 'robin-admin-token';

export const AuthContext = React.createContext();

export const AuthProvider = ({ children }) => {
  const [token, setToken] = useState(null);
  const [user, setUser] = useState(null);

  useEffect(() => {
    let tokenLocal = null;

    try {
      tokenLocal = window.localStorage.getItem(LOCALSTORAGE_TOKEN_KEY);
    } catch (err) {
      console.error(err);
    }

    setToken(tokenLocal);
    getMe();
  }, []);

  const getMe = async () => {
    try {
      const res = await makeReq(`/users/me`, {}, 'GET');
      setUser(res.user);
    } catch (err) {
      setToken(null);
      localStorage.removeItem(LOCALSTORAGE_TOKEN_KEY);
      localStorage.removeItem('user');
    }
  };

  const updateMe = async (newProfile, setState) => {
    try {
      const res = await makeReq(
        `/users/me`,
        { body: { ...newProfile } },
        'PATCH'
      );
      setUser(res.user);
      toast.success('Profile has been updated Successfully !');
    } catch (err) {
      setState(user);
      handleCatch(err);
    }
  };

  const signInUser = (tk, us) => {
    // console.log(`tk`, tk);
    // console.log(`us`, us);

    window.localStorage.setItem(LOCALSTORAGE_TOKEN_KEY, tk);
    // console.log(`window.localStorage.getItem(LOCALSTORAGE_TOKEN_KEY)`);
    setTimeout(() => {
      setToken(tk);
      setUser(us);
    }, 1000);

    toast.success('Logged in Successfully !');
  };

  const logoutUser = () => {
    setToken(null);
    setUser(null);

    localStorage.removeItem('user');
    localStorage.removeItem(LOCALSTORAGE_TOKEN_KEY);

    // setTimeout(() => {
    //   window.location.href = '/';
    // }, 1000);
  };

  // Update password
  const changeMyPassword = async (updatedPassword) => {
    // console.log(`updatedPassword`, updatedPassword);

    try {
      const resData = await makeReq(
        `/users/updatepassword/${user._id}`,
        { body: { password: updatedPassword } },
        'PATCH'
      );
      toast.success('Password Updated Successfully !');
      setUser(resData.user);
    } catch (err) {
      handleCatch(err);
    }
  };

  // update user password
  const changeUserPassword = async (updatedPassword, userId) => {
    // console.log(`updatedPassword`, updatedPassword);

    try {
      const resData = await makeReq(
        `/users/updateuserpassword/${userId}`,
        { body: { password: updatedPassword } },
        'PATCH'
      );
      toast.success('Password Updated Successfully !');
      // no need set user as it is not logged in user!
    } catch (err) {
      handleCatch(err);
    }
  };

  const handleGoogle = async (response) => {
    // console.log(`response`, response);
    try {
      const res = await makeReq(`/auth/googlelogin`, {
        body: { idToken: response.credential },
        method: 'POST',
      });
      // console.log(`res in handleGoogle`, res);
      signInUser(res.token, res.user);
    } catch (err) {
      handleCatch(err);
    }
  };

  return (
    <AuthContext.Provider
      displayName='Auth Context'
      value={{
        token,
        setToken,
        logoutUser,
        user,
        setUser,
        signInUser,
        updateMe,
        changeMyPassword,
        changeUserPassword,
        handleGoogle,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
