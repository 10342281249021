import { useArray } from 'src/hooks';
import React, { useState, useEffect, useContext } from 'react';
import { toast } from 'react-toastify';
import { makeReq, handleCatch } from 'src/utils/makeReq';
import { AuthContext } from './AuthContext';
export const UsersContext = React.createContext();

export const UsersProvider = ({ children }) => {
  const [loading, setLoading] = useState(true);
  const { user } = useContext(AuthContext);

  // Custom hook to manage users.
  const [
    users,
    setUsers,
    pushUser,
    filterUser,
    updateUser,
    removeUser,
    clearUsers,
  ] = useArray([], '_id');

  // Custom hook to manage DELETED users.
  const [
    deletedUsers,
    setDeletedUsers,
    pushDeletedUser,
    filterDeletedUser,
    updateDeletedUser,
    removeDeletedUser,
    clearDeletedUsers,
  ] = useArray([], '_id');

  const fetchUsers = async () => {
    try {
      const resData = await makeReq(`/users`);
      setUsers(resData.users);
    } catch (err) {
      handleCatch(err);
    } finally {
      setLoading(false);
    }
  };

  const getUserById = (id) => {
    return users.find((el) => el._id === id);
  };

  const getDeletedUserById = (id) => {
    return deletedUsers.find((el) => el._id === id);
  };

  // Fetch it once user logged in
  useEffect(() => {
    if (!user) return;
    fetchUsers();
  }, [user]);

  // * CRUD Operations

  const getDeletedUsers = async () => {
    try {
      const resData = await makeReq(`/users?deleted=true`);
      setDeletedUsers(resData.users);
    } catch (err) {
      handleCatch(err);
    } finally {
      setLoading(false);
    }
  };

  const createUser = async (val, callback) => {
    try {
      const resData = await makeReq(
        `/auth/create`,
        { body: { ...val } },
        'POST'
      );
      toast.success('User Created Successfully!');
      pushUser(resData.user);
      callback?.();
      return resData;
    } catch (err) {
      handleCatch(err);
    }
  };

  const editUser = async (id, state, callback) => {
    try {
      const resData = await makeReq(`/users/${id}`, { body: state }, 'PATCH');
      toast.success('User Updated Successfully!');
      updateUser(id, resData.user);
      callback?.();
    } catch (err) {
      handleCatch(err);
    }
  };

  const restoreUser = async (id) => {
    try {
      // set deleted propery to false, to restore the user
      const resData = await makeReq(
        `/users/${id}`,
        {
          body: { deleted: false, deleteReason: null, deletedAt: null },
        },
        'PATCH'
      );
      toast.success('User Restored Successfully!');
      removeDeletedUser(id);
      pushUser(resData.user);
    } catch (err) {
      handleCatch(err);
    }
  };

  const deleteUser = async (id, deleteReason) => {
    try {
      // set deleted propery to true, to soft delete the user + set activated to false
      const resData = await makeReq(
        `/users/${id}`,
        {
          body: {
            deleted: true,
            activated: false,
            deleteReason,
            deletedAt: Date.now(),
          },
        },
        'PATCH'
      );
      toast.success('User Deleted Successfully!');
      removeUser(id);
    } catch (err) {
      handleCatch(err);
    }
  };

  // Soft delete multiple users (backend will set deleted property to true for each user)
  const softDeleteMultipleUsers = async (ids, deleteReason) => {
    try {
      const resData = await makeReq(
        `/users/softdelete-multiple`,
        {
          body: { ids, deleteReason },
        },
        'PATCH'
      );

      // remove deleted users from users array (soft deleted), to update the UI state
      ids.forEach((id) => {
        removeUser(id);
      });

      toast.success('Users Deleted Successfully!');
    } catch (err) {
      handleCatch(err);
    }
  };

  const deleteUserPermanently = async (id) => {
    try {
      await makeReq(`/users/${id}`, {}, 'DELETE');
      toast.success('User Deleted Permanently!');
      removeDeletedUser(id);
    } catch (err) {
      handleCatch(err);
    }
  };

  return (
    <UsersContext.Provider
      displayName='Users Context'
      value={{
        loading,
        users,
        getUserById,
        createUser,
        deleteUser,
        editUser,
        getDeletedUsers,
        deletedUsers,
        getDeletedUserById,
        restoreUser,
        deleteUserPermanently,
        softDeleteMultipleUsers,
      }}
    >
      {children}
    </UsersContext.Provider>
  );
};
