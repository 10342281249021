import React from 'react';
import _ from 'lodash';

// breadcrumbs
import { Link as RouterLink } from 'react-router-dom';
import Link from '@mui/material/Link';

// material
import {
  Button,
  Stack,
  Container,
  Typography,
  Skeleton,
  Card,
  Grid,
  Breadcrumbs,
} from '@mui/material';

// icons
import { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router';
import NotFound from '../../Page404';

// components
import Iconify from '../../../components/Iconify';
import Page from '../../../components/Page';
import AlertDialog from 'src/components/dialogs/AlertDialog';
import { toast } from 'react-toastify';
import { format, add } from 'date-fns';

// Contexts
import { RadarsContext } from 'src/Contexts/RadarsContext';

// ----------------------------------------------------------------------

export default function DeletedRadarDetails() {
  // States for dialogs
  const [openRemoveRadarDialog, setOpenRemoveRadarDialog] = useState(false);
  const [openRestoreRadarDialog, setOpenRestoreRadarDialog] = useState(false);

  // Contexts
  const {
    deletedRadars,
    getDeletedRadarById,
    loading,
    deleteRadarPermanently,
    restoreRadar,
  } = useContext(RadarsContext);

  const { id } = useParams();
  const [radar, setRadar] = useState();
  const [radarNotFound, setRadarNotFound] = useState(false);

  useEffect(() => {
    if (!id) return;
    if (loading) return;

    let el = getDeletedRadarById(id);

    if (!el) {
      setRadarNotFound(true);
      toast.error("The radar with this id doesn't exist.");
    } else {
      setRadar({ ...el });
    }
  }, [getDeletedRadarById, deletedRadars, loading, id]);

  // Handle functions for dialogs
  const handleRemoveRadar = (e) => {
    e.preventDefault();
    deleteRadarPermanently(id);
    setOpenRemoveRadarDialog(false);
  };

  const handleRestoreRadar = (e) => {
    e.preventDefault();
    restoreRadar(id, {
      deleted: false,
      deleteReason: null,
      deletedByUser: null,
      deletedAt: null,
    });
    setOpenRestoreRadarDialog(false);
  };

  return (
    <>
      {radarNotFound || loading ? (
        <Page title='404 | Not Found'>
          {loading ? (
            <Skeleton variant='rectangular' height='600px' />
          ) : (
            <NotFound />
          )}
        </Page>
      ) : (
        <Page title={radar?.customer?.name + ' | Deleted Radar Details'}>
          <Breadcrumbs aria-label='breadcrumb'>
            <Link
              component={RouterLink}
              to='/'
              underline='hover'
              color='inherit'
            >
              Home
            </Link>
            <Link
              component={RouterLink}
              to='/dashboard/radars/deleted'
              underline='hover'
              color='inherit'
            >
              Deleted Radars
            </Link>
            <Typography color='text.primary'>
              Deleted Radar details: {radar?.customer?.name}
            </Typography>
          </Breadcrumbs>
          <Container>
            <Grid justifyContent='flex-start' alignItems='flex-start'>
              <Grid item style={{ marginBottom: '1rem' }}>
                <Typography variant='h4' gutterBottom>
                  Deleted Radar
                </Typography>
                <Card
                  sx={{
                    p: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100%',
                  }}
                >
                  {/* Start of the two-column layout inside the card */}
                  <Grid container spacing={3}>
                    {/* Left Column (Main Radar Details) */}
                    <Grid item xs={8}>
                      <Stack
                        direction='column'
                        alignItems='flex-start'
                        justifyContent='space-around'
                      >
                        {/*  Main radar details go here */}
                        <Typography variant='body1' gutterBottom>
                          <b>Radar ID:</b> {radar?._id}
                        </Typography>
                        <Typography variant='body1' gutterBottom>
                          <b>Radar alias:</b> {radar?.alias || '-'}
                        </Typography>
                        <Typography variant='body1' gutterBottom>
                          <b>Customer full name:</b>{' '}
                          {radar?.customer?.name || '-'}
                        </Typography>
                        <Typography variant='body1' gutterBottom>
                          <b>Customer email:</b> {radar?.customer?.email || '-'}
                        </Typography>
                        <Typography variant='body1' gutterBottom>
                          <b>Customer phone:</b> {radar?.customer?.phone || '-'}
                        </Typography>
                        <Typography variant='body1' gutterBottom>
                          <b>Radar country code: </b>
                          {radar?.countryCode || '-'}
                        </Typography>
                        <Typography variant='body1' gutterBottom>
                          <b>Radar location details: </b>
                          {radar?.detailedLocation || '-'}
                        </Typography>
                        <Typography
                          variant='body1'
                          gutterBottom
                          style={{ wordBreak: 'break-all' }}
                        >
                          <b>Notes: </b>
                          {radar?.notes ? radar?.notes : '-'}
                        </Typography>
                        <Typography variant='body1' gutterBottom>
                          <b>Software type: </b>
                          {radar?.software?.softwareType || 'N/A.'}
                        </Typography>
                        <Typography variant='body1' gutterBottom>
                          <b>Software ubuntu versions: </b>
                          {radar?.software?.ubuntuVersion?.length
                            ? radar?.software?.ubuntuVersion.join(', ')
                            : ' N/A. '}
                        </Typography>
                        <Typography variant='body1' gutterBottom>
                          <b>Software version: </b>
                          {radar?.software?.version || 'N/A.'}
                        </Typography>
                        <Typography variant='body1' gutterBottom>
                          <b>Radar created by: </b>
                          {radar?.createdByUser?.name}
                        </Typography>
                        <Typography variant='body1' gutterBottom>
                          <b>Radar created at: </b>
                          {radar?.createdAt
                            ? format(
                                new Date(radar.createdAt),
                                'yyyy-MM-dd, HH:mm:ss'
                              )
                            : 'N/A'}
                        </Typography>
                      </Stack>
                    </Grid>

                    {/* Right Column (Deleted Related Info) */}
                    <Grid item xs={4}>
                      <Stack
                        direction='column'
                        alignItems='flex-start'
                        justifyContent='space-around'
                        sx={{
                          backgroundColor: '#f8d7da',
                          padding: '1rem',
                          borderRadius: '0.5rem',
                        }}
                      >
                        <Typography variant='body1' gutterBottom>
                          <b>Radar deleted at: </b>
                          {radar?.updatedAt
                            ? format(
                                new Date(radar.updatedAt),
                                'yyyy-MM-dd, HH:mm:ss'
                              )
                            : 'N/A'}
                        </Typography>
                        <Typography variant='body1' gutterBottom>
                          <b>Delete reason by: </b>
                          {radar?.deleteReason || 'N/A'}
                        </Typography>
                        <Typography variant='body1' gutterBottom>
                          <b>Radar deleted by: </b>
                          {radar?.deletedByUser?.name || 'N/A'}
                        </Typography>
                        <Typography variant='body1' gutterBottom>
                          <b>Removal date (Delete at +30 days): </b>
                          {radar?.deletedAt
                            ? format(
                                add(new Date(radar.deletedAt), { days: 30 }),
                                'yyyy-MM-dd'
                              )
                            : 'N/A'}
                        </Typography>
                      </Stack>
                    </Grid>
                  </Grid>
                  <Stack
                    direction='row'
                    alignItems='flex-start'
                    justifyContent='space-between'
                    mt={2}
                    gap='2rem'
                  >
                    <Button
                      variant='contained'
                      color='primary'
                      onClick={() => setOpenRestoreRadarDialog(true)}
                      startIcon={<Iconify icon='ion:arrow-undo' />}
                      sx={{ textTransform: 'none' }}
                    >
                      Restore this radar
                    </Button>
                    <Button
                      variant='contained'
                      color='error'
                      onClick={() => setOpenRemoveRadarDialog(true)}
                      startIcon={<Iconify icon='ion:trash-bin' />}
                      sx={{ textTransform: 'none' }}
                    >
                      Permanently remove this radar
                    </Button>
                  </Stack>
                </Card>
              </Grid>
            </Grid>
          </Container>
          {openRemoveRadarDialog && (
            <AlertDialog
              onConfirm={handleRemoveRadar}
              onCancel={() => setOpenRemoveRadarDialog(false)}
              title={'Are you sure you want to remove this radar?'}
              description={
                'This action will remove the radar from the system. This action cannot be undone.'
              }
            />
          )}
          {openRestoreRadarDialog && (
            <AlertDialog
              onConfirm={handleRestoreRadar}
              onCancel={() => setOpenRestoreRadarDialog(false)}
              title={'Are you sure you want to restore this radar?'}
              description={
                'This action will restore the radar to the system. The radar will be visible in the list of radars. '
              }
            />
          )}
        </Page>
      )}
    </>
  );
}
