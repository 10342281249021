// scroll bar
import 'simplebar/src/simplebar.css';

import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';

// * Styles for some packages ----------
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import 'react-toastify/dist/ReactToastify.css';

//*
import { ToastContainer } from 'react-toastify';
import App from './App';
import { AuthProvider } from './Contexts/AuthContext';
import { RadarsProvider } from './Contexts/RadarsContext';
import { SoftwaresProvider } from './Contexts/SoftwaresContext';
import { UploadedVersionProvider } from './Contexts/UploadedVersionContext';
import { UsersProvider } from './Contexts/UsersContext';
import { LogsProvider } from './Contexts/LogsContext';
import { BackupProvider } from './Contexts/BackupContext';

// * -------------------------------
// ----------------------------------------------------------------------

ReactDOM.render(
  <HelmetProvider>
    <BrowserRouter>
      <ToastContainer
        position='top-right'
        autoClose={3000}
        hideProgressBar
        newestOnTop={true}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <AuthProvider>
        <RadarsProvider>
          <SoftwaresProvider>
            <UploadedVersionProvider>
              <UsersProvider>
                <LogsProvider>
                  <BackupProvider>
                    <App />
                  </BackupProvider>
                </LogsProvider>
              </UsersProvider>
            </UploadedVersionProvider>
          </SoftwaresProvider>
        </RadarsProvider>
      </AuthProvider>
    </BrowserRouter>
  </HelmetProvider>,
  document.getElementById('root')
);
