import { useArray } from 'src/hooks';
import React, { useState, useEffect, useReducer, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { makeReq, handleCatch } from 'src/utils/makeReq';

// contexts
import { AuthContext } from './AuthContext';
import { UploadedVersionContext } from './UploadedVersionContext';
import { SoftwaresContext } from './SoftwaresContext';
import { RadarsContext } from './RadarsContext';

// Backup Context stands for 'Deleted Softwares'. It is used to manage softwares that are deleted by the user.
export const BackupContext = React.createContext();

export const BackupProvider = ({ children }) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const { user } = useContext(AuthContext);
  const { fetchSoftwares: fetchUploadedVersionSoftwares } = useContext(
    UploadedVersionContext
  );
  const { fetchSoftwares: fetchContextSoftwares } =
    useContext(SoftwaresContext);

  const { fetchRadars } = useContext(RadarsContext);

  const [
    backups,
    setBackups,
    pushBackup,
    filterBackup,
    updateBackup,
    removeBackup,
    clearBackup,
  ] = useArray([], '_id');

  const fetchBackups = async () => {
    try {
      const resData = await makeReq(`/backups`);
      setBackups(resData.backups);
    } catch (err) {
      handleCatch(err);
    } finally {
      setLoading(false);
    }
  };

  const getBackupById = (id) => {
    return backups.find((el) => el._id === id);
  };

  //Fetch it once user logged in
  useEffect(() => {
    if (!user) return;
    fetchBackups();
  }, [user]);

  // * CRUD Operations

  // Create a backup from uploaded version
  // Please note that, 'backup' is equivalent to 'deleted' software. It was just named 'backup' initially.
  const createBackup = async (
    _versionPath,
    _versionName,
    _versionUbuntuVersions,
    _softwareType,
    _removeReason
  ) => {
    try {
      const resData = await makeReq(
        `/backups/create`,
        {
          body: {
            versionPath: _versionPath,
            versionName: _versionName,
            versionUbuntuVersions: _versionUbuntuVersions,
            softwareType: _softwareType,
            removeReason: _removeReason,
          },
        },
        'POST'
      );
      pushBackup(resData.backup);

      fetchBackups(); //Update the list of backups. Could be done with updating state!
      fetchUploadedVersionSoftwares(); //Update uploaded softwares list.

      setTimeout(() => {
        toast.success('Backup has been created successfully!');
      }, 1500);
      navigate('/dashboard/softwares/backups');
    } catch (err) {
      handleCatch(err);
    }
  };

  const deleteAllBackups = async () => {
    try {
      await makeReq(`/backups`, {}, 'DELETE');
      setBackups([]);
      setTimeout(() => {
        toast.success('All backups have been deleted successfully!');
      }, 100);
      navigate('/dashboard/softwares/backups');
    } catch (err) {
      handleCatch(err);
    }
  };

  const deleteBackup = async (id) => {
    try {
      await makeReq(`/backups/${id}`, {}, 'DELETE');
      navigate('/dashboard/softwares/backups');
      setTimeout(() => {
        toast.success('Backup has been deleted successfully!');
      }, 100);
      removeBackup(id);
    } catch (err) {
      handleCatch(err);
    }
  };

  const downloadBackup = async (
    id,
    downloadFileName,
    onProgress,
    abortController
  ) => {
    try {
      const resData = await makeReq(
        `/backups/download/${id}`,
        { fileName: downloadFileName, onProgress },
        'GET',
        true,
        abortController
      );
      toast.success('Backup has been downloaded successfully!');
    } catch (err) {
      handleCatch(err);
    }
  };

  const restoreBackup = async (id) => {
    try {
      const resData = await makeReq(`/backups/restore/${id}`, {}, 'PATCH');

      // Update softwares states, and fetch uploaded softwares list.
      fetchContextSoftwares();
      fetchUploadedVersionSoftwares();

      setTimeout(() => {
        toast.success('Backup has been restored successfully!');
      }, 100);
      navigate('/dashboard/softwares/backups');
      removeBackup(id);
    } catch (err) {
      handleCatch(err);
    }
  };

  // Create a 'backup' from a RELEASED software.
  const recallSoftware = async (id, confirmText, callback) => {
    try {
      const resData = await makeReq(
        `/backups/recall/${id}`,
        {
          body: {
            confirmText,
          },
        },
        'PATCH'
      );
      pushBackup(resData.backup); // Push the new backup to the list of backups.
      callback?.();

      // Update softwares list state
      fetchContextSoftwares();

      // Update radar list state
      fetchRadars();

      setTimeout(() => {
        toast.success('Software has been recalled successfully!');
      }, 100);
      navigate('/dashboard/softwares/backups');
    } catch (err) {
      handleCatch(err);
    }
  };

  return (
    <BackupContext.Provider
      displayName='Backups Context'
      value={{
        loading,
        backups,
        getBackupById,
        createBackup,
        deleteAllBackups,
        deleteBackup,
        downloadBackup,
        restoreBackup,
        recallSoftware,
        fetchBackups,
      }}
    >
      {children}
    </BackupContext.Provider>
  );
};
