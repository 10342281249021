import * as React from 'react';
import Button from '@mui/material/Button';

//
import { filter } from 'lodash';
import { useContext, useState } from 'react';

// material
import {
  Card,
  Table,
  Stack,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  Skeleton,
  Tooltip,
} from '@mui/material';

// components
import Page from '../../../components/Page';
import Scrollbar from '../../../components/Scrollbar';
import SearchNotFound from '../../../components/SearchNotFound';
import Iconify from '../../../components/Iconify';
import {
  SoftwareListHead,
  SoftwareListToolbar,
} from '../../../components/_dashboard/software';

//
import { useNavigate } from 'react-router-dom';
import { UploadedVersionContext } from 'src/Contexts/UploadedVersionContext';
import { v4 } from 'uuid';
import format from 'date-fns/format';

// breadcrumb
import { Link as RouterLink } from 'react-router-dom';
import Link from '@mui/material/Link';
import Breadcrumbs from '@mui/material/Breadcrumbs';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  {
    _id: v4(),
    id: 'version',
    label: 'Version',
    alignRight: false,
  },
  {
    _id: v4(),
    id: 'rtype',
    label: 'Type',
    alignRight: false,
  },
  {
    _id: v4(),
    id: 'versionPath',
    label: 'Version path',
    alignRight: false,
  },
  {
    _id: v4(),
    id: 'createdDate',
    label: 'Upload date',
    alignRight: false,
  },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array = [], comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(
      array,
      (_softwares) =>
        _softwares.version.toLowerCase().indexOf(query.toLowerCase()) !== -1
    );
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function Softwares() {
  const { softwares, downloadUploadConfigFile, loading } = useContext(
    UploadedVersionContext
  );
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('desc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('createdDate');
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const navigate = useNavigate();

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  const handleUploadConfigFile = (e) => {
    downloadUploadConfigFile();
  };

  const emptyRows =
    page > 0
      ? Math.max(0, (1 + page) * rowsPerPage - (softwares?.length || 0))
      : 0;

  const filteredSoftwares = applySortFilter(
    softwares,
    getComparator(order, orderBy),
    filterName
  );

  const isSoftwareNotFound = filteredSoftwares.length === 0;

  return (
    <Page title='Uploaded softwares (Create Release) | Robin Radar Systems Admin'>
      <Breadcrumbs aria-label='breadcrumb'>
        <Link component={RouterLink} to='/' underline='hover' color='inherit'>
          Home
        </Link>
        <Typography color='text.primary'>
          Uploaded softwares (Create Release)
        </Typography>
      </Breadcrumbs>
      <Container>
        <Stack
          direction='row'
          alignItems='center'
          justifyContent='space-between'
          mb={5}
        >
          <Stack direction='column' alignItems='flex-start' spacing={0}>
            <Typography variant='h4' gutterBottom>
              Uploaded softwares (Create Release)
            </Typography>
            <Typography variant='h7' gutterBottom>
              (Use the&nbsp;
              <Link
                href='https://pypi.org/project/robin-sd-upload/'
                target='_blank'
                rel='noopener'
              >
                robin-sd-upload
              </Link>
              &nbsp;package to upload a new version to the SD server. Then use
              the create a release here.)
            </Typography>
          </Stack>
        </Stack>

        <Card>
          <Stack
            direction='row'
            alignItems='center'
            justifyContent='space-between'
            mr={3}
          >
            <SoftwareListToolbar
              numSelected={selected.length}
              filterName={filterName}
              onFilterName={handleFilterByName}
              slug='for version...'
            />
            <Tooltip
              title='Download the config file for the robin-sd-upload package. Complete it with your password before use.'
              placement='top-end'
              arrow
              enterDelay={500}
              leaveDelay={200}
            >
              <Button
                variant='contained'
                style={{ textTransform: 'none', minWidth: '175px' }}
                onClick={() => handleUploadConfigFile()}
                startIcon={<Iconify icon='bx:bx-download' />}
              >
                Config for upload
              </Button>
            </Tooltip>
          </Stack>
          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <SoftwareListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={softwares?.length || 0}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  noCheckBox
                />
                <TableBody>
                  {loading
                    ? Array(5)
                        .fill()
                        .map(() => {
                          return (
                            <TableRow
                              hover
                              key={v4()}
                              tabIndex={-1}
                              role='checkbox'
                              sx={{
                                cursor: 'pointer',
                                textDecoration: 'none',
                              }}
                            >
                              {Array(9)
                                .fill()
                                .map(() => (
                                  <TableCell key={v4()} align='right'>
                                    <Skeleton />
                                  </TableCell>
                                ))}
                            </TableRow>
                          );
                        })
                    : filteredSoftwares

                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map((row) => {
                          // ROW variables
                          const {
                            _id,
                            version,
                            versionPath,
                            rtype,
                            createdDate,
                          } = row;

                          const isItemSelected =
                            selected.indexOf(version) !== -1;

                          return (
                            <TableRow
                              hover
                              key={_id}
                              tabIndex={-1}
                              role='checkbox'
                              selected={isItemSelected}
                              aria-checked={isItemSelected}
                              sx={{
                                cursor: 'pointer',
                                textDecoration: 'none',
                              }}
                              onClick={() => {
                                navigate(`/dashboard/uploaded/${_id}`);
                              }}
                            >
                              <TableCell padding='checkbox'></TableCell>
                              <TableCell align='left'>{version}</TableCell>
                              <TableCell align='left'>{rtype}</TableCell>
                              <TableCell align='left'>
                                {versionPath && versionPath.length > 0 ? (
                                  <Tooltip
                                    title={versionPath}
                                    placement='top-start'
                                    arrow
                                  >
                                    <span>
                                      {versionPath.length > 20
                                        ? '...' + versionPath.slice(-20)
                                        : versionPath}
                                    </span>
                                  </Tooltip>
                                ) : (
                                  'Not found.'
                                )}
                              </TableCell>
                              <TableCell align='left'>
                                {format(
                                  new Date(createdDate),
                                  'yyyy-MM-dd, HH:mm:ss'
                                ) || ' - '}
                              </TableCell>
                            </TableRow>
                          );
                        })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
                {isSoftwareNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell
                        align='center'
                        colSpan={TABLE_HEAD.length + 1}
                        sx={{ py: 3 }}
                      >
                        <SearchNotFound searchQuery={filterName} />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25, 50, 100]}
            component='div'
            count={softwares?.length || 0}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>
    </Page>
  );
}
