import React from 'react';
import _ from 'lodash';

// breadcrumbs
import { Link as RouterLink } from 'react-router-dom';
import Link from '@mui/material/Link';

// material
import {
  Button,
  Stack,
  Container,
  Typography,
  Skeleton,
  Card,
  Grid,
  Tooltip,
} from '@mui/material';

// icons
import { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router';
import NotFound from '../../Page404';
import { useNavigate, useLocation } from 'react-router-dom';

// components
import Iconify from '../../../components/Iconify';
import Page from '../../../components/Page';

// Context
import { RadarsContext } from 'src/Contexts/RadarsContext';

//
import { toast } from 'react-toastify';
import Breadcrumbs from '@mui/material/Breadcrumbs';

// dialog
import AlertDialog from 'src/components/dialogs/AlertDialog';
import ConfirmationDialog from 'src/components/dialogs/ConfirmationDialog';

// custom components
import RadarSoftwareCard from './RadarSoftwareCard';
import RadarHistory from './RadarHistory';

// format for date
import format from 'date-fns/format';

// ----------------------------------------------------------------------

export default function RadarDetails() {
  const softwares = useLocation().state;
  const [software, setSoftware] = useState(null);
  const [resetversionfield, setResetVersionField] = useState('3');

  // States for dialog
  const [openUnassignSoftwareDialog, setOpenUnassignSoftwareDialog] =
    useState(false);
  const [openRemoveRadarDialog, setOpenRemoveRadarDialog] = useState(false);

  // Contexts
  const {
    radars,
    getRadarById,
    loading,
    deleteRadar,
    editRadar,
    downloadRadarConfigFile,
    getRadarHistory,
    downloadRadarSoftware,
  } = useContext(RadarsContext);

  const { id } = useParams();
  const [radar, setRadar] = useState();
  const [radarNotFound, setRadarNotFound] = useState(false);
  const [stype, setStype] = useState('');

  const navigate = useNavigate();

  useEffect(() => {
    if (!id) return;
    if (loading) return;

    let el = getRadarById(id);

    if (!el) {
      setRadarNotFound(true);
      toast.error("The radar with this id doesn't exist.");
    } else {
      setRadar({ ...el });
    }
  }, [getRadarById, radars, loading, id]);

  const sortIt = (sortBy) => (a, b) => {
    if (a[sortBy] > b[sortBy]) {
      return 1;
    } else if (a[sortBy] < b[sortBy]) {
      return -1;
    }
    return 0;
  };

  // call sort then filter softwares by recall, then sort by softwareType
  let sortedSoftwares = [...softwares];
  sortedSoftwares.sort(sortIt('softwareType'));

  // filter recalled softwares
  let sortedNotRecalledSoftwares = sortedSoftwares.filter((obj) => {
    return obj.recalled === false;
  });

  const changeStype = (event) => {
    setStype(event.target.value);
    setResetVersionField(_.uniqueId());
    setSoftware(null);
  };

  //handle functions
  const handleAssignSoftware = (e) => {
    e.preventDefault();
    console.log('software new value: ', software._id);
    editRadar(id, { software: software._id });
  };

  const handleUnassignSoftware = (e) => {
    e.preventDefault();
    editRadar(id, { software: null });
    setOpenUnassignSoftwareDialog(false);
    // clear the 'select software version' value to None after unassigning
    setResetVersionField(_.uniqueId());
    setStype('');
    setSoftware(null);
  };

  // Download software related
  const [downloadProgress, setDownloadProgress] = useState(0);
  const [downloadStatus, setDownloadStatus] = useState(null);
  const [abortController, setAbortController] = useState(null);

  const handleProgress = (progress) => {
    setDownloadProgress(progress);
    if (progress === 100) {
      setDownloadStatus('completed');
    } else {
      setDownloadStatus('downloading');
    }
  };

  const handleDownloadSoftware = (e) => {
    e.preventDefault();
    // Create a new AbortController for each download, so that we can abort the download when needed
    const newAbortController = new AbortController();
    setAbortController(newAbortController);
    const versionforDownload = radar?.software?.version;

    // Check if softwareVersion is null
    if (!versionforDownload) {
      toast.error('No software to download.');
      return;
    }

    // Add .zip at the end if softwareVersion includes '.zip'
    const downloadFileName = versionforDownload.includes('.zip')
      ? versionforDownload
      : `${versionforDownload}.zip`;

    // Reset progress and status before starting a new download
    setDownloadProgress(0);
    setDownloadStatus(null);

    // Start the download
    downloadRadarSoftware(
      id,
      downloadFileName,
      handleProgress,
      newAbortController
    );
  };

  const handleStopDownload = () => {
    if (abortController) {
      abortController.abort(); // Abort the download
      setDownloadStatus(null); // Reset the download status
      setDownloadProgress(0); // Reset the download progress
      setAbortController(null); // Reset the abortController
    }
  };

  const handleEditRadarDetails = (e) => {
    e.preventDefault();
    navigate(`/dashboard/radars/${id}/edit`);
  };

  const handleRemoveRadar = (inputText) => {
    deleteRadar(id, inputText);
    setOpenRemoveRadarDialog(false);
  };

  const handleDownloadConfigFile = (e) => {
    e.preventDefault();
    downloadRadarConfigFile(id);
  };

  return (
    <>
      {radarNotFound || loading ? (
        <Page title='404 | Not Found'>
          {loading ? (
            <Skeleton variant='rectangular' height='600px' />
          ) : (
            <NotFound />
          )}
        </Page>
      ) : (
        <Page title={radar?.customer?.name + ' | Radar Details'}>
          <Breadcrumbs aria-label='breadcrumb'>
            <Link
              component={RouterLink}
              to='/'
              underline='hover'
              color='inherit'
            >
              Home
            </Link>
            <Link
              component={RouterLink}
              to='/dashboard/radars/list'
              underline='hover'
              color='inherit'
            >
              Radars
            </Link>
            <Typography color='text.primary'>
              Radar details: {radar?.customer?.name}
            </Typography>
          </Breadcrumbs>
          <Container>
            <Grid justifyContent='flex-start' alignItems='flex-start'>
              <Grid item style={{ marginBottom: '1rem' }}>
                <Typography variant='h4' gutterBottom>
                  Radar
                </Typography>
                <Card
                  sx={{
                    p: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100%',
                    minWidth: '60vw',
                    maxWidth: '32.5rem',
                  }}
                >
                  <Stack
                    direction='column'
                    alignItems='flex-start'
                    justifyContent='space-around'
                  >
                    <Typography variant='body1' gutterBottom>
                      <b>Radar ID:</b> {radar?._id}
                    </Typography>
                    <Typography variant='body1' gutterBottom>
                      <b>Radar alias:</b> {radar?.alias || '-'}
                    </Typography>
                    <Typography variant='body1' gutterBottom>
                      <b>Customer full name:</b> {radar?.customer?.name || '-'}
                    </Typography>
                    <Typography variant='body1' gutterBottom>
                      <b>Customer email:</b> {radar?.customer?.email || '-'}
                    </Typography>
                    <Typography variant='body1' gutterBottom>
                      <b>Customer phone:</b> {radar?.customer?.phone || '-'}
                    </Typography>
                    <Typography variant='body1' gutterBottom>
                      <b>Radar country code: </b>
                      {radar?.countryCode || '-'}
                    </Typography>
                    <Typography variant='body1' gutterBottom>
                      <b>Radar location details: </b>
                      {radar?.detailedLocation || '-'}
                    </Typography>
                    <Typography
                      variant='body1'
                      gutterBottom
                      style={{ wordBreak: 'break-all' }}
                    >
                      <b>Notes: </b>
                      {radar?.notes ? radar?.notes : '-'}
                    </Typography>
                    <Typography variant='body1' gutterBottom>
                      <b>Radar created by: </b>
                      {radar?.createdByUser?.name}
                    </Typography>
                    <Typography variant='body1' gutterBottom>
                      <b>Radar created at: </b>
                      {radar?.createdAt
                        ? format(
                            new Date(radar.createdAt),
                            'yyyy-MM-dd, HH:mm:ss'
                          )
                        : 'N/A'}
                    </Typography>
                    <Typography variant='body1' gutterBottom>
                      <b>Radar updated at: </b>
                      {radar?.updatedAt
                        ? format(
                            new Date(radar.updatedAt),
                            'yyyy-MM-dd, HH:mm:ss'
                          )
                        : 'N/A'}
                    </Typography>
                    <Typography variant='body1' gutterBottom>
                      <b>Testing purpose: </b>
                      {radar?.testing ? 'Yes' : 'No'}
                    </Typography>
                  </Stack>
                  <Stack
                    direction='row'
                    alignItems='flex-start'
                    justifyContent='space-between'
                    mt={2}
                    gap='2rem'
                  >
                    <Stack direction='row' alignItems='flex-start' gap='1rem'>
                      <Tooltip
                        title='Download the config file for the robin-sd-download package. Complete it with the correct password before use.'
                        placement='bottom-end'
                        arrow
                        enterDelay={500}
                        leaveDelay={200}
                      >
                        <Button
                          variant='contained'
                          style={{ textTransform: 'none' }}
                          color='primary'
                          onClick={handleDownloadConfigFile}
                          startIcon={<Iconify icon='bx:bx-download' />}
                        >
                          Config for download
                        </Button>
                      </Tooltip>
                      <Button
                        variant='contained'
                        style={{ textTransform: 'none' }}
                        color='info'
                        onClick={handleEditRadarDetails}
                        startIcon={<Iconify icon='bx:edit' />}
                      >
                        Edit
                      </Button>
                    </Stack>
                    <Button
                      variant='contained'
                      color='error'
                      onClick={() => setOpenRemoveRadarDialog(true)}
                      startIcon={<Iconify icon='ion:trash-bin' />}
                      sx={{ textTransform: 'none' }}
                    >
                      Remove radar
                    </Button>
                  </Stack>
                </Card>
              </Grid>
              <Grid item style={{ marginBottom: '1rem' }}>
                <RadarSoftwareCard
                  radar={radar}
                  downloadStatus={downloadStatus}
                  downloadProgress={downloadProgress}
                  handleStopDownload={handleStopDownload}
                  handleDownloadSoftware={handleDownloadSoftware}
                  setOpenUnassignSoftwareDialog={setOpenUnassignSoftwareDialog}
                  stype={stype}
                  changeStype={changeStype}
                  sortedNotRecalledSoftwares={sortedNotRecalledSoftwares}
                  resetversionfield={resetversionfield}
                  software={software}
                  setSoftware={setSoftware}
                  handleAssignSoftware={handleAssignSoftware}
                />
              </Grid>
              <Grid item style={{ marginBottom: '1rem' }}>
                <RadarHistory />
              </Grid>
            </Grid>
          </Container>
          {openUnassignSoftwareDialog && (
            <AlertDialog
              onConfirm={handleUnassignSoftware}
              onCancel={() => setOpenUnassignSoftwareDialog(false)}
              title={'Are you sure you want to unassign this software?'}
              description={
                'This action will remove the software from this radar. This action cannot be undone.'
              }
            />
          )}
          {openRemoveRadarDialog && (
            <ConfirmationDialog
              onConfirm={(inputText) => handleRemoveRadar(inputText)}
              onCancel={() => setOpenRemoveRadarDialog(false)}
              title={'Are you sure you want to remove this radar?'}
              description={
                'This action will remove the radar from the system. The radar will be permanently deleted after a period of time. Please note that, the deleted radar cannot be used for any purpose.' +
                ' You can only delete radar created by you. If you want to remove a radar created by another user, please contact someone (admin) who has the permission to do so.'
              }
            />
          )}
        </Page>
      )}
    </>
  );
}
