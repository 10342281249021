import { LOCALSTORAGE_TOKEN_KEY } from 'src/Contexts/AuthContext';
import { toast } from 'react-toastify';

//* Development URLs
let API_BASE_URL = 'https://software-api-client.robinradar.systems/api';
let API_BASE_ORIGIN = 'https://software-api-client.robinradar.systems';

if (process.env.NODE_ENV === 'development') {
  API_BASE_URL = 'http://localhost:5001/api';
  API_BASE_ORIGIN = 'http://localhost:5001';
}

const handleCatch = (err) => {
  let errMsg = 'Something Went Wrong';
  if (err.message) errMsg = err.message;
  toast.error(errMsg);
};

const triggerDownload = (url, fileName) => {
  const link = document.createElement('a');
  link.href = url;
  link.download = fileName;
  link.setAttribute('download', link.download);
  link.click();
};

const makeReq = (
  endpoint,
  { body, fileName, onProgress, ...customConfig } = {},
  method = 'GET',
  download = false,
  abortController // Add abortController as a parameter
) => {
  const token = localStorage.getItem(LOCALSTORAGE_TOKEN_KEY);
  const headers = { 'Content-Type': 'application/json' };

  if (token) {
    headers.Authorization = `Bearer ${token}`;
  }

  const config = {
    method: method,
    ...customConfig,
    headers: {
      ...headers,
      ...customConfig.headers,
    },
  };

  if (download && abortController) {
    config.signal = abortController.signal;
  }

  if (body) {
    config.body = JSON.stringify(body);
  }

  return fetch(`${API_BASE_URL}${endpoint}`, config).then(async (res) => {
    if (download) {
      const totalSize = parseInt(res.headers.get('Content-Length'), 10);
      const reader = res.body.getReader();
      let loadedSize = 0;

      const chunks = [];
      // eslint-disable-next-line no-constant-condition
      while (true) {
        const { done, value } = await reader.read();
        if (done) {
          break;
        }
        chunks.push(value);
        loadedSize += value.length;
        const progress = (loadedSize / totalSize) * 100;
        if (onProgress) {
          onProgress(progress);
        }
      }

      const data = new Blob(chunks);
      const url = URL.createObjectURL(data);
      const downloadFileName = fileName || `${endpoint.split('/').pop()}`;

      triggerDownload(url, downloadFileName); // Use the triggerDownload function
      return Promise.resolve(data);
    }

    const data = await res.json();

    if (res.ok) {
      return data;
    } else {
      return Promise.reject(data);
    }
  });
};

export { API_BASE_URL, makeReq, handleCatch, API_BASE_ORIGIN };
