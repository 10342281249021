import React from 'react';
import { LinearProgress, Typography } from '@mui/material';

const DownloadProgress = ({ downloadStatus, downloadProgress }) => {
  if (downloadStatus !== 'downloading') return null;

  return (
    <>
      <LinearProgress
        variant='determinate'
        value={downloadProgress}
        sx={{ mt: 2 }}
      />
      <Typography variant='body2' color='text.secondary' sx={{ mt: 1 }}>
        Download progress: {downloadProgress.toFixed(1)}%
      </Typography>
    </>
  );
};

export default DownloadProgress;
