import { filter } from 'lodash';
import { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Link as RouterLink } from 'react-router-dom';

// material
import {
  Card,
  Table,
  Stack,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  Skeleton,
  Button,
  Tooltip,
  Link,
} from '@mui/material';

// components
import Page from '../../components/Page';
import Scrollbar from '../../components/Scrollbar';
import SearchNotFound from '../../components/SearchNotFound';
import { LogListHead, LogListToolbar } from '../../components/_dashboard/logs_';
import AlertDialog from 'src/components/dialogs/AlertDialog';
import Iconify from '../../components/Iconify';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import { format } from 'date-fns';
import { v4 } from 'uuid';

// Contexts
import { LogsContext } from 'src/Contexts/LogsContext';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { _id: v4(), id: 'method', label: 'Method type', alignRight: false },
  {
    _id: v4(),
    id: 'url',
    label: 'URL',
    alignRight: false,
  },
  {
    _id: v4(),
    id: 'status',
    label: 'Status',
    alignRight: false,
  },
  {
    _id: v4(),
    id: 'createdAt',
    label: 'Request time',
    alignRight: false,
  },
  {
    _id: v4(),
    id: 'user.name',
    label: 'Request user',
    alignRight: false,
  },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  const keys = orderBy.split('.');
  const aProperty = keys.length > 1 ? a?.[keys[0]]?.[keys[1]] : a?.[orderBy];
  const bProperty = keys.length > 1 ? b?.[keys[0]]?.[keys[1]] : b?.[orderBy];

  if (!aProperty && !bProperty) {
    return 0;
  }
  if (!aProperty) {
    return 1;
  }
  if (!bProperty) {
    return -1;
  }
  if (bProperty < aProperty) {
    return -1;
  }
  if (bProperty > aProperty) {
    return 1;
  }
  return 0;
}
function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array = [], comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(
      array,
      (_logs) => _logs.method.toLowerCase().indexOf(query.toLowerCase()) !== -1
    );
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function Logs() {
  const [page, setPage] = useState(0);
  const { logs, loading, deleteAllLogs } = useContext(LogsContext);
  const [order, setOrder] = useState('desc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('createdAt');
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const navigate = useNavigate();

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - (logs?.length || 0)) : 0;

  const filteredLogs = applySortFilter(
    logs,
    getComparator(order, orderBy),
    filterName
  );

  const isLogNotFound = filteredLogs.length === 0;

  // Alert dialogs
  const [openDeleteAllLogsDialog, setOpenDeleteAllLogsDialog] = useState(false);

  const handleDeleteAllLogs = (event) => {
    event.preventDefault();

    deleteAllLogs();

    setOpenDeleteAllLogsDialog(false);
  };

  return (
    <Page title='Logs | Robin Radar Systems Admin'>
      <Breadcrumbs aria-label='breadcrumb'>
        <Link component={RouterLink} to='/' underline='hover' color='inherit'>
          Home
        </Link>
        <Typography color='text.primary'>Logs</Typography>
      </Breadcrumbs>
      <Container>
        <Stack
          direction='row'
          alignItems='center'
          justifyContent='space-between'
          mb={5}
        >
          <Typography variant='h4' gutterBottom>
            Logs
          </Typography>
        </Stack>

        <Card>
          <Stack
            direction='row'
            alignItems='center'
            justifyContent='space-between'
            mr={3}
          >
            <LogListToolbar
              numSelected={selected.length}
              filterName={filterName}
              onFilterName={handleFilterByName}
              slug='for method...'
            />
            <Button
              sx={{ textTransform: 'none' }}
              variant='contained'
              color='error'
              onClick={() => setOpenDeleteAllLogsDialog(true)}
              startIcon={<Iconify icon='eva:trash-2-outline' />}
            >
              Delete logs
            </Button>
          </Stack>

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <LogListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={logs?.length || 0}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  noCheckBox
                />
                <TableBody>
                  {loading
                    ? Array(5)
                        .fill()
                        .map(() => {
                          return (
                            <TableRow
                              hover
                              key={v4()}
                              tabIndex={-1}
                              role='checkbox'
                              sx={{
                                cursor: 'pointer',
                                textDecoration: 'none',
                              }}
                            >
                              {Array(9)
                                .fill()
                                .map(() => (
                                  <TableCell key={v4()} align='right'>
                                    <Skeleton />
                                  </TableCell>
                                ))}
                            </TableRow>
                          );
                        })
                    : filteredLogs

                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map((row) => {
                          const { _id, method, url, status, createdAt, user } =
                            row;

                          const isItemSelected = selected.indexOf(logs) !== -1;

                          return (
                            <TableRow
                              hover
                              key={_id}
                              tabIndex={-1}
                              role='checkbox'
                              selected={isItemSelected}
                              aria-checked={isItemSelected}
                              sx={{
                                cursor: 'pointer',
                                textDecoration: 'none',
                              }}
                              onClick={() =>
                                navigate(`/dashboard/logs/${row._id}`, {
                                  state: logs,
                                })
                              }
                            >
                              <TableCell padding='checkbox'></TableCell>
                              <TableCell align='left'>{method}</TableCell>
                              <Tooltip title={url} placement='top-start' arrow>
                                <TableCell align='left'>
                                  {url.length > 45
                                    ? url.substr(0, 45) + '...'
                                    : url}
                                </TableCell>
                              </Tooltip>
                              <TableCell align='left'>{status}</TableCell>
                              <TableCell align='left'>
                                {format(
                                  new Date(createdAt),
                                  'yyyy-MM-dd, HH:mm:ss'
                                )}
                              </TableCell>
                              <TableCell align='left'>
                                {user?.name || 'N/A'}
                              </TableCell>
                            </TableRow>
                          );
                        })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
                {isLogNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell
                        align='center'
                        colSpan={TABLE_HEAD.length + 1}
                        sx={{ py: 3 }}
                      >
                        <SearchNotFound searchQuery={filterName} />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25, 50, 100]}
            component='div'
            count={logs?.length || 0}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>
      {openDeleteAllLogsDialog && (
        <AlertDialog
          onConfirm={handleDeleteAllLogs}
          onCancel={() => setOpenDeleteAllLogsDialog(false)}
          title={`Are you sure you want to delete all logs?`}
          description={
            'This action cannot be undone. This will delete all logs from the database.'
          }
        />
      )}
    </Page>
  );
}
