import { useArray } from 'src/hooks';
import React, { useState, useEffect, useReducer, useContext } from 'react';
import { toast } from 'react-toastify';
import { makeReq, handleCatch } from 'src/utils/makeReq';
import { AuthContext } from './AuthContext';
import { useNavigate } from 'react-router-dom';

export const RadarsContext = React.createContext();

export const RadarsProvider = ({ children }) => {
  const [loading, setLoading] = useState(true);
  const { user } = useContext(AuthContext);
  const navigate = useNavigate();

  // This custom hook is used to manage radars.
  const [
    radars,
    setRadars,
    pushRadar,
    filterRadar,
    updateRadar,
    removeRadar,
    clearRadars,
  ] = useArray([], '_id');

  // This custom hook is used to manage DELETED radars.
  const [
    deletedRadars,
    setDeletedRadars,
    pushDeletedRadar,
    filterDeletedRadar,
    updateDeletedRadar,
    removeDeletedRadar,
    clearDeletedRadars,
  ] = useArray([], '_id');

  const fetchRadars = async () => {
    try {
      const resData = await makeReq(`/radars`);
      setRadars(resData.radars);
    } catch (err) {
      handleCatch(err);
    } finally {
      setLoading(false);
    }
  };

  const getRadarById = (id) => {
    return radars.find((el) => el._id === id);
  };

  const getDeletedRadarById = (id) => {
    return deletedRadars.find((el) => el._id === id);
  };

  const filteredRadars = radars.filter(
    (radar) => !radar.software || radar.software === ''
  );

  // Fetch it once user logged in
  useEffect(() => {
    if (!user) return;
    fetchRadars();
  }, [user]);

  // * CRUD Operations

  const getDeletedRadars = async () => {
    try {
      const resData = await makeReq(`/radars?deleted=true`);
      setDeletedRadars(resData.radars);
    } catch (err) {
      handleCatch(err);
    } finally {
      setLoading(false);
    }
  };

  const createRadar = async (val, callback) => {
    try {
      const resData = await makeReq(`/radars`, { body: { ...val } }, 'POST');
      toast.success('Radar has been created successfully!');
      pushRadar(resData.radar);
      callback?.();
      fetchRadars();
      // setTimeout(() => {
      //   navigate('/dashboard/radars/list');
      // }, 1500);
    } catch (err) {
      handleCatch(err);
    }
  };

  const deleteRadar = async (id, _removeReason) => {
    try {
      await makeReq(
        `/radars/${id}`,
        {
          body: {
            removeReason: _removeReason,
          },
        },
        'DELETE'
      );

      navigate('/dashboard/radars/list');
      toast.success('Radar has been deleted successfully!');
      removeRadar(id);
    } catch (err) {
      handleCatch(err);
    }
  };

  const deleteRadarPermanently = async (id) => {
    try {
      await makeReq(`/radars/${id}?permanentDelete=true`, {}, 'DELETE');
      navigate('/dashboard/radars/deleted');
      toast.success('Radar has been deleted permanently!');
      removeDeletedRadar(id);
    } catch (err) {
      handleCatch(err);
    }
  };

  const softDeleteMultipleRadars = async (ids, _removeReason) => {
    try {
      await makeReq(
        `/radars/softdelete-multiple`,
        {
          body: {
            ids,
            removeReason: _removeReason,
          },
        },
        'PATCH'
      );
      toast.success('Radars have been deleted successfully!');

      // Remove deleted radars from radars state
      ids.forEach((id) => {
        removeRadar(id);
      });
    } catch (err) {
      handleCatch(err);
    }
  };

  const downloadRadarSoftware = async (
    id,
    downloadFileName,
    onProgress,
    abortController
  ) => {
    try {
      const resData = await makeReq(
        `/radars/${id}/software`,
        { fileName: downloadFileName, onProgress },
        'GET',
        true,
        abortController // Pass the abortController to abort the request when the user clicks the cancel button.
      );
      toast.success('Software has been downloaded successfully!');
    } catch (err) {
      handleCatch(err);
    }
  };

  const unAssignRadarSoftware = async (id) => {
    try {
      const resData = await makeReq(`/radars/${id}/software`, {}, 'DELETE');
      toast.success('Software has been unassigned successfully!');
      // updateRadar(id, resData.radar);
      // callback?.();
    } catch (err) {
      handleCatch(err);
    }
  };

  const restoreRadar = async (id, state, callback) => {
    try {
      const resData = await makeReq(`/radars/${id}`, { body: state }, 'PATCH');
      navigate('/dashboard/radars/deleted');
      toast.success('Radar has been restored successfully!');

      // remove restored radar from deletedRadars state
      removeDeletedRadar(id);

      // add restored radar to radars state
      pushRadar(resData.radar);

      callback?.();
    } catch (err) {
      handleCatch(err);
    }
  };

  const editRadar = async (id, state, callback) => {
    try {
      const resData = await makeReq(`/radars/${id}`, { body: state }, 'PATCH');
      toast.success('Radar has been updated successfully!');
      updateRadar(id, resData.radar);
      callback?.();
    } catch (err) {
      handleCatch(err);
    }
  };

  const downloadRadarConfigFile = async (id) => {
    try {
      // Check if radar exists with the given ID
      const radar = radars.find((el) => el._id === id);
      if (!radar) {
        toast.error('Radar not found!');
        return;
      }
      if (!radar.customer) {
        toast.error(
          'Customer details not found! Download empty config file instead, on Account page'
        );
        return;
      }

      const fileName = `sd-download-config.yml`;

      // Make an async request to download the radar config file
      const resData = await makeReq(
        `/radars/${id}/config`,
        { fileName },
        'GET',
        true
      );

      if (!resData || resData.error) {
        toast.error('Error downloading the config file');
        return;
      }

      toast.success('Config file has been downloaded successfully!');

      // Use the response data as needed, e.g., downloadRadar(resData)
    } catch (err) {
      handleCatch(err);
    }
  };

  const downloadEmptyRadarConfigFile = async () => {
    try {
      const fileName = `sd-download-config.yml`;

      // Make an async request to download the radar config file
      const resData = await makeReq(
        `/radars/config`,
        { fileName },
        'GET',
        true
      );

      if (!resData || resData.error) {
        toast.error('Error downloading the config file');
        return;
      }

      toast.success('Config file has been downloaded successfully!');
    } catch (err) {
      handleCatch(err);
    }
  };

  const getAllRadarHistory = async () => {
    try {
      const resData = await makeReq(`/radars/history`, {}, 'GET');
      return resData.history;
    } catch (err) {
      handleCatch(err);
    }
  };

  // Get radar history by software ID, this is for recalled software
  const getRadarHistoryBySoftwareId = async (id) => {
    try {
      const resData = await makeReq(
        `/radars/history/software/${id}`,
        {},
        'GET'
      );
      return resData.history;
    } catch (err) {
      handleCatch(err);
    }
  };

  // Get radar history by radar ID
  const getRadarHistory = async (id) => {
    try {
      const resData = await makeReq(`/radars/history/${id}`, {}, 'GET');
      return resData.history;
    } catch (err) {
      handleCatch(err);
    }
  };

  const getRadarCountBySoftwareType = () => {
    const radarCountBySoftwareType = radars.reduce((acc, radar) => {
      if (radar.software && radar.software.softwareType) {
        const softwareType = radar.software.softwareType;
        if (!acc[softwareType]) {
          acc[softwareType] = 0;
        }
        acc[softwareType]++;
      }
      return acc;
    }, {});

    // Convert object to the desired array format
    const result = Object.keys(radarCountBySoftwareType).map(
      (softwareType) => ({
        type: softwareType,
        value: radarCountBySoftwareType[softwareType],
      })
    );

    return result;
  };

  return (
    <RadarsContext.Provider
      displayName='Radars Context'
      value={{
        loading,
        radars,
        filteredRadars,
        getRadarById,
        createRadar,
        deleteRadar,
        downloadRadarSoftware,
        unAssignRadarSoftware,
        editRadar,
        downloadRadarConfigFile,
        downloadEmptyRadarConfigFile,
        fetchRadars,
        getAllRadarHistory,
        getRadarHistoryBySoftwareId,
        getRadarHistory,
        getRadarCountBySoftwareType,
        getDeletedRadars,
        deletedRadars,
        getDeletedRadarById,
        restoreRadar,
        deleteRadarPermanently,
        softDeleteMultipleRadars,
      }}
    >
      {children}
    </RadarsContext.Provider>
  );
};
