import PropTypes from 'prop-types';
import { Icon } from '@iconify/react';
import searchFill from '@iconify/icons-eva/search-fill';
import trash2Fill from '@iconify/icons-eva/trash-2-fill';
import roundFilterList from '@iconify/icons-ic/round-filter-list';
import Iconify from '../../../components/Iconify';

// material
import { styled } from '@mui/material/styles';
import {
  Box,
  Toolbar,
  Tooltip,
  IconButton,
  Typography,
  OutlinedInput,
  InputAdornment,
  Stack,
  FormControl,
  InputLabel,
  Select,
  Chip,
  MenuItem,
  Button,
} from '@mui/material';

// ----------------------------------------------------------------------

const RootStyle = styled(Toolbar)(({ theme }) => ({
  height: 96,
  display: 'flex',
  justifyContent: 'space-between',
  padding: theme.spacing(0, 1, 0, 3),
}));

const SearchStyle = styled(OutlinedInput)(({ theme }) => ({
  width: 300,
  transition: theme.transitions.create(['box-shadow', 'width'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  '&.Mui-focused': { width: 350, boxShadow: theme.customShadows.z8 },
  '& fieldset': {
    borderWidth: `1px !important`,
    borderColor: `${theme.palette.grey[500_32]} !important`,
  },
}));

// ----------------------------------------------------------------------

UserListToolbar.propTypes = {
  numSelected: PropTypes.number,
  filterName: PropTypes.string,
  onFilterName: PropTypes.func,
  slug: PropTypes.string,
  showFilterIcon: PropTypes.bool,
  onTrashClick: PropTypes.func,

  showCreateButton: PropTypes.bool,
};

UserListToolbar.defaultProps = {
  showFilterIcon: false,
  showCreateButton: false,
};

export default function UserListToolbar({
  numSelected,
  filterName,
  onFilterName,
  slug,
  navigate,
  showFilterIcon,
  selectedRoles,
  handleRoleChange,
  selectedDropdownFilter,
  handleDropdownFilterChange,
  showCreateButton,
  onTrashClick,
}) {
  return (
    <RootStyle
      sx={{
        ...(numSelected > 0 && {
          color: 'primary.main',
          bgcolor: 'primary.lighter',
        }),
      }}
    >
      {numSelected > 0 ? (
        <Typography component='div' variant='subtitle1'>
          {numSelected} selected
        </Typography>
      ) : (
        <>
          <Stack
            direction='row'
            alignItems='flex-start'
            justifyContent='flex-start'
          >
            <SearchStyle
              value={filterName}
              onChange={onFilterName}
              placeholder={`Search ${slug || ''}`}
              startAdornment={
                <InputAdornment position='start'>
                  <Box
                    component={Icon}
                    icon={searchFill}
                    sx={{ color: 'text.disabled' }}
                  />
                </InputAdornment>
              }
            />

            <FormControl sx={{ minWidth: 200, marginLeft: 2 }}>
              <InputLabel id='test-select-label'>Role</InputLabel>
              <Select
                labelId='demo-multiple-chip-label'
                id='demo-multiple-chip'
                multiple
                label='Role'
                value={selectedRoles}
                onChange={handleRoleChange}
                renderValue={(selected) => (
                  <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                    {selected.map((value) => (
                      <Chip key={value} label={value} />
                    ))}
                  </Box>
                )}
              >
                <MenuItem value={'admin'}>Admin</MenuItem>
                <MenuItem value={'S&S'}>S&S</MenuItem>
                <MenuItem value={'R&D'}>R&D</MenuItem>
                <MenuItem value={'customer'}>Customer</MenuItem>
              </Select>
            </FormControl>
            <FormControl sx={{ minWidth: 200, marginLeft: 2 }}>
              <InputLabel id='test-select-label'>Filter</InputLabel>
              <Select
                labelId='demo-simple-select-label'
                id='demo-simple-select'
                value={selectedDropdownFilter}
                label='Filter'
                onChange={handleDropdownFilterChange}
              >
                <MenuItem value={'show-all'}>Show all</MenuItem>
                <MenuItem value={'hide-test'}>Hide test users</MenuItem>
                <MenuItem value={'show-test'}>Show only test users</MenuItem>
              </Select>
            </FormControl>
          </Stack>
          {showCreateButton && (
            <Button
              variant='contained'
              sx={{ mb: 2.5, ml: 2.5 }}
              onClick={() => navigate('/dashboard/user/new')}
              startIcon={<Iconify icon='eva:plus-fill' />}
            >
              Create User
            </Button>
          )}
        </>
      )}
      {numSelected > 0 ? (
        <Tooltip title='Delete'>
          <IconButton onClick={onTrashClick}>
            <Icon icon={trash2Fill} />
          </IconButton>
        </Tooltip>
      ) : (
        <>
          {showFilterIcon && (
            <Tooltip title='Filter list'>
              <IconButton>
                <Icon icon={roundFilterList} />
              </IconButton>
            </Tooltip>
          )}
        </>
      )}
    </RootStyle>
  );
}
