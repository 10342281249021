import { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { Link as RouterLink } from 'react-router-dom';

// material
import {
  Button,
  Stack,
  Container,
  Typography,
  Skeleton,
  Card,
  Grid,
  Link,
  Breadcrumbs,
  Box,
  Divider,
} from '@mui/material';
import { red } from '@mui/material/colors';

// Contexts
import { SoftwaresContext } from 'src/Contexts/SoftwaresContext';
import { BackupContext } from 'src/Contexts/BackupContext';
import { RadarsContext } from 'src/Contexts/RadarsContext';

// components
import NotFound from '../../Page404';
import Iconify from '../../../components/Iconify';
import Page from '../../../components/Page';
import { toast } from 'react-toastify';
import format from 'date-fns/format';
import AlertDialog from 'src/components/dialogs/AlertDialog';
import InfoDialog from 'src/components/dialogs/InfoDialog';
import DownloadProgress from 'src/components/common/DownloadProgress';

//

export default function ReleasedSoftwareDetails() {
  // Contexts
  const {
    softwares,
    getSoftwareById,
    loading,
    deleteSoftware,
    downloadSoftware,
    checkUsedSoftwares,
  } = useContext(SoftwaresContext);

  const { recallSoftware, fetchBackups } = useContext(BackupContext);
  const { radars, getRadarHistoryBySoftwareId } = useContext(RadarsContext);

  // States
  const { id } = useParams();
  const [software, setSoftware] = useState();
  const [radarHistoryList, setRadarHistoryList] = useState([]);
  const [radarHistoryLoading, setRadarHistoryLoading] = useState(true);
  const [radarLoading, setRadarLoading] = useState(true);
  const [softwareNotFound, setSoftwareNotFound] = useState(false);
  const [openDeleteVersionDialog, setOpenDeleteVersionDialog] = useState(false);
  const [usedSoftwares, setUsedSoftwares] = useState([]);
  const [usedSoftwaresForInfoDialog, setUsedSoftwaresForInfoDialog] = useState(
    []
  );
  const [openInfoDialog, setOpenInfoDialog] = useState(false);
  const [downloadProgress, setDownloadProgress] = useState(0);
  const [downloadStatus, setDownloadStatus] = useState(null);
  const [abortController, setAbortController] = useState(null);

  const handleProgress = (progress) => {
    setDownloadProgress(progress);
    if (progress === 100) {
      setDownloadStatus('completed');
    } else {
      setDownloadStatus('downloading');
    }
  };

  const handleDownloadSoftware = (e) => {
    e.preventDefault();
    const newAbortController = new AbortController();
    setAbortController(newAbortController);
    const versionforDownload = software?.version;
    // Check if softwareVersion is null
    if (!versionforDownload) {
      toast.error('No software to download.');
      return;
    }

    // Add .zip at the end if softwareVersion includes '.zip'
    const downloadFileName = versionforDownload.includes('.zip')
      ? versionforDownload
      : `${versionforDownload}.zip`;

    // Reset progress and status before starting a new download
    setDownloadProgress(0);
    setDownloadStatus(null);

    // Start the download
    downloadSoftware(id, downloadFileName, handleProgress, newAbortController);
  };

  const handleStopDownload = () => {
    if (abortController) {
      abortController.abort(); // Abort the download
      setDownloadStatus(null); // Reset the download status
      setDownloadProgress(0); // Reset the download progress
      setAbortController(null); // Reset the abortController
    }
  };

  const handleRecallSoftware = async (inputText) => {
    await recallSoftware(id, inputText, () => {
      setOpenInfoDialog(false);
    });
  };

  const handleDeleteVersion = (e) => {
    e.preventDefault();
    deleteSoftware(id);
    setOpenDeleteVersionDialog(false);
  };

  const fetchRadarHistory = async (id) => {
    const radarHistory = await getRadarHistoryBySoftwareId(id);
    if (radarHistory) {
      setRadarHistoryList(radarHistory);
      setRadarHistoryLoading(false);
    }
  };

  useEffect(() => {
    if (!id) return;
    if (loading) return;

    let el = getSoftwareById(id);

    fetchRadarHistory(id);
    handleCheckUsedSoftwares(id);

    if (!el) {
      setSoftwareNotFound(true);
      toast.error('The software with this id does not exist.');
    } else {
      setSoftware({ ...el });
    }
  }, [getSoftwareById, softwares, loading, id, getRadarHistoryBySoftwareId]);

  const handleCheckUsedSoftwares = async (id) => {
    try {
      const response = await checkUsedSoftwares(id);
      setUsedSoftwares(response.radars);
      setUsedSoftwaresForInfoDialog(response);
      setRadarLoading(false);
    } catch (error) {
      toast.error('An error occurred while checking used software.');
    }
  };

  //
  const openRecallReleaseConfirmationDialog = async (id) => {
    setOpenInfoDialog(true);
  };

  return (
    <>
      {softwareNotFound || loading ? (
        <Page title='404 | Not Found'>
          {loading ? (
            <Skeleton variant='rectangular' height='600px' />
          ) : (
            <NotFound />
          )}
        </Page>
      ) : (
        <Page title={software?.version + ' | Robin Radar Systems'}>
          <Breadcrumbs aria-label='breadcrumb'>
            <Link
              component={RouterLink}
              to='/'
              underline='hover'
              color='inherit'
            >
              Home
            </Link>
            <Link
              component={RouterLink}
              to='/dashboard/softwares/installed'
              underline='hover'
              color='inherit'
            >
              Released softwares
            </Link>
            <Typography color='text.primary'>{software?.version}</Typography>
          </Breadcrumbs>
          <Container>
            <Grid
              container
              direction='column'
              justifyContent='space-between'
              alignItems='stretch'
              spacing={2}
            >
              <Grid item>
                <Typography variant='h4' gutterBottom>
                  Released software <i>({software?.version})</i>
                </Typography>
                <Typography variant='h5' gutterBottom>
                  Software details
                </Typography>
                <Card
                  sx={{
                    p: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100%',
                  }}
                >
                  <Stack
                    direction='column'
                    alignItems='flex-start'
                    justifyContent='space-around'
                    mt={2}
                  >
                    <Typography variant='body1' gutterBottom>
                      <b>Software type: </b> {software?.softwareType}
                    </Typography>
                    <Typography variant='body1' gutterBottom>
                      <b>ID of software: </b> {software?._id}
                    </Typography>
                    <Typography variant='body1' gutterBottom>
                      <b>Version: </b>
                      {software?.version}
                    </Typography>
                    <Typography variant='body1' gutterBottom>
                      <b>Software release path: </b> {software?.softwarePath}
                    </Typography>
                    <Typography variant='body1' gutterBottom>
                      <b>Radar type(s): </b>
                      {software?.radarType?.length
                        ? software.radarType.join(', ')
                        : 'not specified when software was installed'}
                    </Typography>
                    <Typography variant='body1' gutterBottom>
                      <b>Ubuntu version(s): </b>
                      {software?.ubuntuVersion?.length
                        ? software.ubuntuVersion.join(', ')
                        : ' - '}
                    </Typography>
                    <Typography variant='body1' gutterBottom>
                      <b>Created at: </b>
                      {software?.createdAt
                        ? format(
                            new Date(software?.createdAt),
                            'yyyy-MM-dd, HH:mm:ss'
                          )
                        : ' - '}
                    </Typography>
                    <Typography variant='body1' gutterBottom>
                      <b>Recalled:</b>
                      {software?.recalled == false
                        ? ' no, this is an active version.'
                        : ' yes, this version is NOT active.'}
                    </Typography>
                    {software?.recalled && (
                      <Typography variant='body1' gutterBottom>
                        <b>Recalled at: </b>
                        {software?.updatedAt
                          ? format(
                              new Date(software?.updatedAt),
                              'yyyy-MM-dd, HH:mm:ss'
                            )
                          : ' - '}
                      </Typography>
                    )}
                  </Stack>
                  <>
                    <Stack
                      direction='row'
                      alignItems='flex-start'
                      justifyContent='space-between'
                      mt={2}
                      mb={2}
                      gap='2rem'
                    >
                      {software?.recalled ? (
                        <Button
                          sx={{ textTransform: 'none' }}
                          variant='contained'
                          color='error'
                          onClick={() => setOpenDeleteVersionDialog(true)}
                          startIcon={<Iconify icon='eva:trash-outline' />}
                        >
                          Remove recalled log
                        </Button>
                      ) : (
                        <>
                          <Button
                            sx={{ textTransform: 'none' }}
                            variant='contained'
                            onClick={
                              downloadStatus === 'downloading'
                                ? handleStopDownload
                                : handleDownloadSoftware
                            }
                            startIcon={
                              <Iconify
                                icon={
                                  downloadStatus === 'downloading'
                                    ? 'ic:baseline-stop'
                                    : 'ic:baseline-download'
                                }
                              />
                            }
                          >
                            {downloadStatus === 'downloading'
                              ? 'Stop download'
                              : 'Download software'}
                          </Button>
                          <Button
                            sx={{ textTransform: 'none' }}
                            variant='contained'
                            color='error'
                            onClick={openRecallReleaseConfirmationDialog}
                            startIcon={<Iconify icon='eva:undo-outline' />}
                          >
                            Recall release
                          </Button>
                        </>
                      )}
                    </Stack>
                    <DownloadProgress
                      downloadStatus={downloadStatus}
                      downloadProgress={downloadProgress}
                    />
                  </>
                </Card>
              </Grid>
              {!software?.recalled && (
                <Grid item>
                  <Typography variant='h5' gutterBottom>
                    Radars that actively use this version
                  </Typography>
                  {usedSoftwares.length > 0 ? (
                    <>
                      Be careful when recalling a released software version. If
                      you do so, all radars that use this version will be
                      affected.
                      <br />
                      <strong>
                        {'(Found ' + usedSoftwares.length + ' radars.)'}
                      </strong>
                      <Card sx={{ p: 2, mt: 2 }}>
                        <Stack sx={{ overflowY: 'auto', maxHeight: '400px' }}>
                          {radarLoading ? (
                            <Skeleton variant='rectangular' height='60px' />
                          ) : (
                            usedSoftwares.map((radar, index) => (
                              <Box
                                key={radar.id}
                                sx={{
                                  backgroundColor:
                                    index % 2 ? 'white' : red[50],
                                  borderRadius: '5px',
                                  mb: 1,
                                }}
                              >
                                <Typography variant='body1'>
                                  <b>Radar ID: </b>
                                  {radar.id}
                                  <br />
                                  <b>Created at: </b>
                                  {radar.createdAt
                                    ? format(
                                        new Date(radar.createdAt),
                                        'yyyy-MM-dd, HH:mm:ss'
                                      )
                                    : ' - '}
                                  <br />
                                  <b>Radar alias: </b>
                                  {radar.alias ? radar.alias : ' - '}
                                  <br />
                                  <b>Customer name: </b>
                                  {radar.customer}
                                  <br />
                                </Typography>
                                <Divider />
                              </Box>
                            ))
                          )}
                        </Stack>
                      </Card>
                    </>
                  ) : (
                    'No radars use this version.'
                  )}
                </Grid>
              )}

              {software?.recalled && (
                <Grid item>
                  <Typography variant='h5' gutterBottom>
                    Radars History
                  </Typography>

                  {radarHistoryList.length > 0 ? (
                    <>
                      Since this version was recalled, it can not be used by any
                      radar anymore. List of radars that used this version in
                      the past, before it was recalled.
                      <br />
                      Please note that the history list might not be complete,
                      as history data is only stored for a limited time. <br />
                      <strong>
                        {' (Found ' +
                          radarHistoryList.length +
                          ' occurrences.)'}
                      </strong>
                      <Card sx={{ p: 2, mt: 2 }}>
                        <Stack sx={{ overflowY: 'auto', maxHeight: '400px' }}>
                          {radarHistoryLoading ? (
                            <Skeleton variant='rectangular' height='60px' />
                          ) : (
                            radarHistoryList.map((radar, index) => (
                              <Box
                                key={radar._id}
                                sx={{
                                  backgroundColor:
                                    index % 2 === 0 ? '#f5f5f5' : '#ffffff',
                                }}
                              >
                                <Typography variant='body1'>
                                  <b>Radar alias</b>
                                  {radar.history.radarAlias || ' - '}
                                  <br />
                                  <b>Radar ID: </b>
                                  {radar.history.radar || ' - '}
                                  <br />
                                  <b>Customer name: </b>
                                  {radar.history.customerName || ' - '}
                                  <br />
                                  <b>Updated at: </b>
                                  {format(
                                    new Date(radar.history.updatedAt),
                                    'yyyy-MM-dd, HH:mm:ss'
                                  ) || ' - '}
                                  <br />
                                  <b>Update type: </b>
                                  {radar.history.updateType === 'U'
                                    ? 'Unassigned this recalled software from radar'
                                    : radar.history.updateType ?? ' - '}
                                  <br />
                                  <b>Updated by: </b>
                                  {radar.history.updatedByUser || ' - '}
                                  <br />
                                  <b>Notes: </b>
                                  {radar.history.notes || ' - '}
                                  <br />
                                  <br />
                                </Typography>
                                <Divider />
                              </Box>
                            ))
                          )}
                        </Stack>
                      </Card>
                    </>
                  ) : (
                    <>
                      There were no radars that used this software version
                      previously.
                    </>
                  )}
                </Grid>
              )}
            </Grid>
          </Container>

          {openInfoDialog && (
            <InfoDialog
              title='Are you sure you want to recall this version?'
              description='It is possible that this version is used by other radars. If you recall this version, it will be removed from the system and from the radars that use it.
        This action cannot be undone. Check the list of radars that use this version below, and enter the reason for recalling this version:'
              data={usedSoftwaresForInfoDialog}
              onConfirm={handleRecallSoftware}
              onCancel={() => setOpenInfoDialog(false)}
            />
          )}

          {openDeleteVersionDialog && (
            <AlertDialog
              onConfirm={handleDeleteVersion}
              onCancel={() => setOpenDeleteVersionDialog(false)}
              title={
                'Are you sure you want to remove this log from the database?'
              }
              description={
                'This action will remove the log for a previously recalled software from the system. This action cannot be undone.'
              }
            />
          )}
        </Page>
      )}
    </>
  );
}
